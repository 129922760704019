import styled from 'styled-components'

export const InfoContainer = styled.div`
	${'' /* background: ${({ lightBg }) => (lightBg ? '#add9af' : '#f5f5f5')}; */}
	${'' /* background: ${({ lightBg }) => (lightBg ? '#def2df' : '#828187')}; */}
	border-radius: 2rem;
	position: relative;
	max-width: 120rem;
	margin: 0 auto;
	margin-bottom: 1rem;

	background: linear-gradient(cyan, transparent),
		linear-gradient(-45deg, magenta, transparent),
		linear-gradient(45deg, yellow, transparent);
	background-blend-mode: multiply;

	@media screen and (max-width: 768px) {
		padding: 5rem 0;
	}
`
export const Grid2Layout = styled.div`
	display: grid;
	column-gap: 2rem;
	${'' /* row-gap: 9.6rem; */}

	grid-template-columns: 2fr 1fr;
	align-items: center;

	@media screen and (max-width: 768px) {
		grid-template-columns: 1fr;
		justify-content: center;
		align-items: top;
		height: 100%;
	}
`
export const H3heading = styled.h3`
	font-size: 2rem;
	padding-bottom: 1rem;
`
export const FirstColDiv = styled.div`
	align-items: center;
	justify-content: center;
`
export const SubTitle2 = styled.p`
	font-size: 1.8rem;
	line-height: 1.8;
	padding-left: 6rem;
	color: #010606;

	@media screen and (max-width: 768px) {
		font-size: 1.5rem;
		padding-left: 1rem;
	}
`
export const ImageWrap = styled.div`
	position: relative;

	display: flex;
	align-items: center;
	justify-content: center;
	${
		'' /* &:before {
		content: '';
		display: block;
		border-radius: 50%;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 60%;

		padding-bottom: 60%;

		background-color: #fdf2e9;

		z-index: -2;
	}
	&:after {
		content: '';
		display: block;
		border-radius: 50%;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 45%;
		padding-bottom: 45%;
		background-color: #fae5d3;

		z-index: -1;
	} */
	}
`

export const Image = styled.img`
	width: 100%;
	padding: 0 0;
	border-radius: 16px;
	margin-right: 10rem;
	margin-top: -2rem;

	@media screen and (max-width: 768px) {
		display: none;
	}
`
export const InfoWrapper = styled.div`
	display: grid;
	position: relative;
	width: 100%;
	max-width: 1200px;
	margin-right: auto;
	margin-left: auto;
	padding: 0 24px;
`

export const InfoRow = styled.div`
	display: grid;
	grid-template-areas: 3fr 1fr;
`

export const Column1 = styled.div`
	margin-bottom: 15px;
	padding: 0 15px;
	grid-area: col1;
`

export const Column2 = styled.div`
	margin-bottom: 15px;
	padding: 0 15px;
	grid-area: col2;
`

export const TextWrapper = styled.div`
	max-width: 540px;
	padding-top: 0;
	${'' /* padding-bottom: 60px; */}
`

export const TopLine = styled.p`
	color: #010606;
	font-size: 24px;
	line-height: 16px;
	font-weight: 700;
	letter-spacing: 1.4px;
	text-transform: uppercase;
	margin-bottom: 26px;
	margin-top: 25px;
`

// export const Heading = styled.h1`
// 	margin-bottom: 24px;
// 	font-size: 48px;
// 	line-height: 1.1;
// 	font-weight: 600;
// 	color: ${({ lightText }) => (lightText ? '#f7f8fa' : '#010606')};

// 	@media screen and (max-width: 480px) {
// 		font-size: 32px;
// 	}
// `
export const Heading = styled.h1`
	margin-bottom: 24px;
	font-size: 48px;
	line-height: 1.1;
	font-weight: 600;
	color: ${({ lightText }) => (lightText ? '#f7f8fa' : '#010606')};

	@media screen and (max-width: 480px) {
		font-size: 32px;
	}
`
export const H1Title = styled.h1`
	font-size: 2.7rem;
	font-weight: 600;
	text-align: center;
	${'' /* text-transform: none; */}

	color: ${({ lightText }) => (lightText ? '#f7f8fa' : '#010606')};

	@media screen and (max-width: 480px) {
		font-size: 1.5rem;
		margin-top: 4rem;
	}

	@media screen and (max-width: 768px) {
		font-size: 1.5rem;
		margin-top: -4rem;
	}
`

export const SubTitle = styled.p`
	max-width: 440px;
	margin-bottom: 35px;
	font-size: 18px;
	line-height: 24px;
	color: ${({ darkText }) => (darkText ? '#010606' : '#fff')};
`
export const SubTitle1 = styled.p`
	max-width: 440px;
	margin-bottom: 35px;
	font-size: 18px;
	font-weight: 700;
	line-height: 24px;
	color: ${({ darkText }) => (darkText ? '#010606' : '#fff')};
`

export const BtnWrap = styled.div`
	display: flex;
	justify-content: flex-start;
`

export const ImgWrap = styled.div`
	max-width: 555px;
	height: 100%;
`

export const Img = styled.img`
	width: 50%;
	height: 50%;
	margin: 0 0 10px 0;
	padding-right: 0;
`

export const InfoSectionLinksContainer = styled.div`
	display: flex;
	justify-content: center;

	@media screen and (max-width: 820px) {
		padding-top: 32px;
	}
`

export const InfoSectionLinksWrapper = styled.div`
	display: flex;

	@media screen and (max-width: 820px) {
		flex-direction: column;
	}
`

export const InfoSectionLinkItems = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin: 16px;
	text-align: left;
	width: 160px;
	box-sizing: border-box;
	color: #fff;

	@media screen and (max-width: 420px) {
		margin: 0;
		padding: 10px;
		width: 100%;
	}
`

export const InfoSectionLinkTitle = styled.h1`
	font-size: 16px;
	margin-bottom: 16px;
	color: #fff;
`
export const InfoSectionLinkColumn = styled.h2`
	font-size: 14px;
	margin-bottom: 0.5rem;
	color: #fff;
`
