import React from 'react'

import { AdminHome } from '../components/Admin/AdminHome'

import { useSelector } from 'react-redux'

const HomeScreen = () => {
	//get user info
	const userLogin = useSelector((state) => state.userLogin)
	const { userInfo } = userLogin

	return <>{userInfo && userInfo.isAdmin && <AdminHome />}</>
}

export default HomeScreen

//keyword={keyword && keyword} - If keyword exists then use keyword
// {
// 	/* <div style={{ backgroundImage: `url(${infoBackground})` }}> */
// }
