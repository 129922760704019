import React from 'react'
import { useDispatch } from 'react-redux'
// import { Link } from 'react-router-dom'
import { Card, Button } from 'react-bootstrap'
import { addCartItem } from '../actions/cartActions'

const Product = ({ product }) => {
	const dispatch = useDispatch()

	const addToCartHandler = (product) => {
		//this line will add the qty to url
		// history.push(`/cart/${product._id}?qty=${qty}`)

		// history.push('/cart')
		dispatch(addCartItem(product))
		// history.push('/cart')
	}

	return (
		<Card
			className='my-3 p-3 rounded'
			style={{
				border: 'none',
				boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
				transition: '0.3s',
				borderRadius: '2rem',
			}}
		>
			{/* <Link to={`/product/${product._id}`}> */}
			<Card.Img src={product.image} variant='top' />
			{/* </Link> */}

			<Card.Body>
				{/* <Link to={`/product/${product._id}`}> */}
				<Card.Title as='div'>
					<strong style={{ fontSize: '1.2rem' }}>{product.name}</strong>
				</Card.Title>
				{/* </Link> */}

				<Card.Text as='h3' style={{ paddingBottom: 0, marginBottom: 0 }}>
					${product.price}
				</Card.Text>
			</Card.Body>

			<Button
				style={{
					fontSize: '1.4rem',
					paddingLeft: '6rem',
					paddingRight: '6rem',
					paddingTop: '1rem',
					paddingBottom: '1rem',
					borderRadius: '.5rem',
					color: '#fff',
					backgroundColor: '#e67e22',
					boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
					transition: '0.3s',
					textAlign: 'center',
					textDecoration: 'none',
				}}
				type='button'
				onClick={() => addToCartHandler(product)}
			>
				DONATE
			</Button>

			{/* <Link to={`/cart/${product._id}?qty=${qty}`}>
				<Card.Title as='div' style={{ marginTop: '1rem', marginLeft: '1rem' }}>
					<strong
						style={{
							fontSize: '1.4rem',
							paddingLeft: '6rem',
							paddingRight: '6rem',
							paddingTop: '1rem',
							paddingBottom: '1rem',
							borderRadius: '.5rem',
							color: '#fff',
							backgroundColor: '#e67e22',
							boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
							transition: '0.3s',
							textAlign: 'center',
							textDecoration: 'none',
						}}
					>
						ADD TO CART
					</strong>
				</Card.Title>
			</Link> */}
		</Card>
	)
}

//This is how you pass default prop
// Rating.defaultProps = {
// 	color: '#f8e825',
// }

//This will typecheck the props.
// Rating.propTypes = {
// 	value: PropTypes.number.isRequired,
// 	text: PropTypes.string.isRequired,
// 	color: PropTypes.string,
// }

export default Product
