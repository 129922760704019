import React, { useState, useEffect } from 'react'
import SliderImage from './SliderImages'
import Arrows from './SliderArrows'
import Dots from './SliderDots'
import SliderContent from './SliderContent'
import './slider.css'

const len = SliderImage.length - 1

const Slider = () => {
	const [activeIndex, setActiveIndex] = useState(0)

	useEffect(() => {
		const interval = setInterval(() => {
			setActiveIndex(activeIndex === len ? 0 : activeIndex + 1)
		}, 7000)
		return () => clearInterval(interval)
	}, [activeIndex])

	return (
		<div className='slider-container'>
			<SliderContent activeIndex={activeIndex} />
			<Arrows
				prevSlide={() =>
					setActiveIndex(activeIndex < 1 ? len : activeIndex - 1)
				}
				nextSlide={() =>
					setActiveIndex(activeIndex === len ? 0 : activeIndex + 1)
				}
			/>
			<Dots
				activeIndex={activeIndex}
				onclick={(activeIndex) => setActiveIndex(activeIndex)}
			/>
		</div>
	)
}

export default Slider
