import {
	CART_ADD_ITEM,
	CART_REMOVE_ITEM,
	// CART_SAVE_SHIPPING_ADDRESS,
	CART_SAVE_PAYMENT_METHOD,
	START_ADD_TO_CART,
	DECREASE_CART_ITEM,
	CART_CLEAR_ALL,
} from '../constants/cartConstants'

// state = { cartItems: [], shippingAddress: {} }, removed shipping address from below line
export const cartReducer = (state = { cartItems: [] }, action) => {
	switch (action.type) {
		case CART_ADD_ITEM:
			const item = action.payload

			const existItem = state.cartItems.find((x) => x.product === item.product)

			if (existItem) {
				return {
					...state,
					cartItems: state.cartItems.map((x) =>
						x.product === existItem.product ? item : x
					),
				}
			} else {
				return {
					...state,
					cartItems: [...state.cartItems, item],
				}
			}

		//ADD TO CART button on the PoojaScreen and also increase cart items
		case START_ADD_TO_CART:
			//check whether the product received is already in the cart or not
			const existingIndex = state.cartItems.findIndex(
				(item1) => item1.product._id === action.payload.product._id
			)

			if (existingIndex >= 0) {
				state.cartItems[existingIndex] = {
					...state.cartItems[existingIndex],
					cartQuantity: state.cartItems[existingIndex].cartQuantity + 1,
				}
			} else {
				let tempProductItem = { ...action.payload, cartQuantity: 1 }

				state.cartItems.push(tempProductItem)
			}

			return {
				...state,
				cartItems: [...state.cartItems],
			}

		//Decrease cart items
		case DECREASE_CART_ITEM:
			//check whether the product is already in the cart or not
			const itemIndex = state.cartItems.findIndex(
				(item2) => item2.product._id === action.payload.product._id
			)

			if (state.cartItems[itemIndex].cartQuantity > 1) {
				state.cartItems[itemIndex].cartQuantity -= 1
			} else if (state.cartItems[itemIndex].cartQuantity === 1) {
				const nextCartItems = state.cartItems.filter(
					(item) => item.product._id !== action.payload.product._id
				)

				state.cartItems = nextCartItems
			}

			return {
				...state,
				cartItems: [...state.cartItems],
			}

		//Remove cart items
		case CART_REMOVE_ITEM:
			const newCartItems = state.cartItems.filter(
				(item) => item.product._id !== action.payload.product._id
			)
			return {
				...state,
				cartItems: [...newCartItems],
			}

		//Clear All items in the cart after successfull Payment
		case CART_CLEAR_ALL:
			return {
				cartItems: [],
				cartQuantity: 0,
				total: 0,
			}

		case CART_SAVE_PAYMENT_METHOD:
			//action.payload is the data passed in from the CartScreen
			return {
				...state,
				paymentMethod: action.payload,
			}

		default:
			return state
	}
}
