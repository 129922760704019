import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import FormContainer from '../components/FormContainer'
import { login } from '../actions/userActions'
import {
	LoginContainer,
	H1heading,
	H2heading,
	StatusMessages,
	Img,
	SignInButton,
	SignInLinkButton,
} from './SignInScreenElements'
import SaiBhagawan from '../assests/saibaba-newburgh1-modified.png'
// import { ReactComponent as ShoppingBag } from '../assests/shopping-bag.svg'

const SignInScreen = ({ location, history }) => {
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')

	const dispatch = useDispatch()

	const userLogin = useSelector((state) => state.userLogin)

	const { loading, error, userInfo } = userLogin

	const redirect = location.search ? location.search.split('=')[1] : '/'

	useEffect(() => {
		if (userInfo) {
			history.push(redirect)
		}
	}, [history, userInfo, redirect])

	const submitHandler = (e) => {
		e.preventDefault()
		//DISPATCH LOGIN
		dispatch(login(email, password))

		// console.log('userInfo: ', userInfo)

		// if (userInfo && userInfo.isAdmin) {
		// 	history.push('/adminhome')
		// }
	}

	return (
		<>
			<LoginContainer>
				<div
					style={{
						textAlign: 'center',
						marginTop: '1rem',
					}}
				>
					<Img src={SaiBhagawan} alt='sing in screen' />
				</div>
				<FormContainer>
					<H1heading>Sign In</H1heading>
					<StatusMessages>
						{error && <Message variant='danger'>{error}</Message>}
					</StatusMessages>

					{loading && <Loader />}

					<Form onSubmit={submitHandler}>
						<H2heading>
							<Form.Group controlId='email'>
								<Form.Label>Email Address</Form.Label>

								<Form.Control
									style={{ fontSize: '1.4rem', border: '1px solid black' }}
									type='email'
									placeholder='Enter your email id'
									value={email}
									required
									onChange={(e) => setEmail(e.target.value)}
								></Form.Control>
							</Form.Group>

							<Form.Group
								controlId='password'
								style={{
									paddingTop: '2rem',
								}}
							>
								<Form.Label>Password</Form.Label>
								<Form.Control
									style={{ fontSize: '1.4rem', border: '1px solid black' }}
									type='password'
									placeholder='Enter your password'
									required
									value={password}
									onChange={(e) => setPassword(e.target.value)}
								></Form.Control>
							</Form.Group>
						</H2heading>

						<Row style={{ marginTop: '-1rem', marginBottom: '4rem' }}>
							<Col text-center>
								<SignInButton>Sign In</SignInButton>
							</Col>
						</Row>
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								marginBottom: '4rem',
							}}
						>
							<Row>
								<Col
									style={{
										fontSize: '1.5rem',
										marginTop: '1rem',
										marginLeft: '10%',
									}}
								>
									New User? &nbsp;&nbsp;&nbsp;
									<SignInLinkButton>
										<Link
											style={{
												color: 'green',
												fontWeight: 'bold',
												textDecoration: 'none',
												fontSize: '1.4rem',
											}}
											to={
												redirect
													? `/register?redirect=${redirect}`
													: '/register'
											}
										>
											Register
										</Link>
									</SignInLinkButton>
								</Col>

								{/* <Col
									style={{
										textTransform: 'none',
										color: '#010606',
										fontWeight: 'bold',
										fontSize: '1.3rem',
										marginTop: '3rem',
									}}
								>
									New User?
								</Col>
							 
								<Col style={{ marginLeft: '-6%' }}>
									<SignInLinkButton>
										<Link
											style={{
												color: 'green',
												fontWeight: 'bold',
												textDecoration: 'none',
												fontSize: '1.4rem',
											}}
											to={
												redirect
													? `/register?redirect=${redirect}`
													: '/register'
											}
										>
											Register
										</Link>
									</SignInLinkButton>
								</Col> */}

								<Col style={{ fontSize: '1.5rem', marginTop: '1rem' }}>
									Forgot Password? &nbsp;&nbsp;&nbsp;
									<SignInLinkButton>
										<Link
											style={{
												color: 'green',
												fontWeight: 'bold',
												textDecoration: 'none',
												fontSize: '1.3rem',
											}}
											to={
												redirect
													? `/forgotpass?redirect=${redirect}`
													: '/forgotpass'
											}
										>
											Reset Password
										</Link>
									</SignInLinkButton>
								</Col>
							</Row>
						</div>
					</Form>

					{/* <Row
						style={{
							marginTop: '2.7rem',
							marginBottom: '6rem',
							marginLeft: '14rem',
						}}
					>
						<Col style={{ fontSize: '1.5rem' }}>
							New User? &nbsp;&nbsp;&nbsp;
							<Link
								style={{
									color: '#fff',
									fontSize: '1.5rem',
									backgroundColor: '#e67e22',
									padding: '1rem',
									borderRadius: '.5rem',
									textDecoration: 'none',
								}}
								to={redirect ? `/register?redirect=${redirect}` : '/register'}
							>
								Register
							</Link>
						</Col>
					</Row> */}

					{/* <Row
						style={{
							marginTop: '2.5rem',
							marginBottom: '5rem',
							marginLeft: '14rem',
						}}
					>
						<Col style={{ fontSize: '1.5rem' }}>
							Forgot Password? &nbsp;&nbsp;&nbsp;
							<Link
								style={{
									color: '#fff',
									fontSize: '1.5rem',
									backgroundColor: '#e67e22',
									padding: '0.8rem',
									borderRadius: '0.5rem',
									textDecoration: 'none',
								}}
								to={
									redirect ? `/forgotpass?redirect=${redirect}` : '/forgotpass'
								}
							>
								Forgot Password
							</Link>
						</Col>
					</Row> */}
				</FormContainer>
			</LoginContainer>
		</>
	)
}

export default SignInScreen
