import React, { useState } from 'react'
import { Form, Button, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import FormContainer from '../components/FormContainer'
// import CheckoutSteps from '../components/CheckoutSteps'
import { savePaymentMethod } from '../actions/cartActions'

const PaymentScreen = ({ history }) => {
	// const cart = useSelector((state) => state.cart)
	// const { shippingAddress } = cart

	const userLogin = useSelector((state) => state.userLogin)
	const { userInfo } = userLogin

	//If no shippingaddress then go back to shipping screen
	// if (!shippingAddress) {
	// 	history.push('/shipping')
	// }

	const [paymentMethod, setPaymentMethod] = useState('')

	const dispatch = useDispatch()

	const submitHandler = (e) => {
		e.preventDefault()
		dispatch(savePaymentMethod(paymentMethod))

		//move on to next page after the dispatch
		if (paymentMethod === 'Check') {
			history.push('/placecheckorder')
		} else {
			history.push('/placeorder')
		}
	}

	return (
		<FormContainer>
			{/* <CheckoutSteps step1 step2 step3 /> */}
			<h1 style={{ marginBottom: '4rem', marginTop: '2rem' }}>
				Payment Method
			</h1>
			<Form onSubmit={submitHandler}>
				{userInfo && userInfo.isAdmin && (
					<Form.Group>
						<Form.Label
							as='legend'
							style={{ marginBottom: '4rem', marginTop: '3rem' }}
						>
							Select Method
						</Form.Label>

						<Col>
							<Form.Check
								style={{ fontSize: '1.3rem' }}
								type='radio'
								label='&nbsp; &nbsp; Pay By Credit Card'
								id='card'
								name='paymentMethod'
								value='CreditCard'
								// checked={true}
								onClick={(e) => setPaymentMethod(e.target.value)}
							></Form.Check>

							<Form.Check
								style={{ fontSize: '1.3rem', marginTop: '2rem' }}
								type='radio'
								label='&nbsp; &nbsp; Pay By Bank Check'
								id='check'
								name='paymentMethod'
								value='Check'
								onClick={(e) => setPaymentMethod(e.target.value)}
							></Form.Check>
						</Col>
					</Form.Group>
				)}
				{/* <Col>
					<Form.Check
						label='Stripe'
						id='Stripe'
						name='paymentMethod'
						value='Stripe'
						onChange={(e) => setPaymentMethod(e.target.value)}
					></Form.Check>
				</Col> */}

				<Button
					type='submit'
					variant='primary'
					style={{
						marginTop: '4rem',
						marginBottom: '4rem',
						fontSize: '1.2rem',
					}}
				>
					Continue
				</Button>
			</Form>
		</FormContainer>
	)
}

export default PaymentScreen
