import React from 'react'
import Slider from '../components/Slider'
import PoojaTimings from '../components/PoojaTimings'
import ServicesVideo from '../components/ServicesVideo'
import { NewsLetter } from '../components/newsLetter/NewsLetter'
import { YoutubePlayer } from '../components/youtubePlayer/YoutubePlayer'

// import { Modal } from '../components/homepageAlert/Modal'
import { AdminHome } from '../components/Admin/AdminHome'

import { useSelector } from 'react-redux'

const HomeScreen = () => {
	// const [showModal, setShowModal] = useState(true)

	//get user info
	const userLogin = useSelector((state) => state.userLogin)
	const { userInfo } = userLogin

	if (userInfo) {
	}

	return (
		<>
			{userInfo && userInfo.isAdmin === true ? <AdminHome /> : null}

			{/* {userInfo === null && (
				<Modal showModal={showModal} setShowModal={setShowModal} />
			)} */}

			{(userInfo === null || userInfo.isAdmin === false) && <Slider />}
			{(userInfo === null || userInfo.isAdmin === false) && <PoojaTimings />}
			{(userInfo === null || userInfo.isAdmin === false) && <ServicesVideo />}
			{(userInfo === null || userInfo.isAdmin === false) && <YoutubePlayer />}
			{(userInfo === null || userInfo.isAdmin === false) && <NewsLetter />}
		</>
	)
}

export default HomeScreen

//keyword={keyword && keyword} - If keyword exists then use keyword
// {
// 	/* <div style={{ backgroundImage: `url(${infoBackground})` }}> */
// }
