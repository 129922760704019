import {
	TOGGLE_NAVBAR_REQUEST,
	// TOGGLE_NAVBAR_SUCCESS,
	// TOGGLE_NAVBAR_FAIL,
	START_VIDEO_PLAYER_REQUEST,
	START_VIDEO_PLAYER_SUCCESS,
	START_VIDEO_PLAYER_FAIL,
	STOP_VIDEO_PLAYER_REQUEST,
	STOP_VIDEO_PLAYER_SUCCESS,
	STOP_VIDEO_PLAYER_FAIL,
} from '../constants/youtubeConstants'

export const toggleNavbar = () => {
	return (dispatch) => {
		return dispatch({ type: TOGGLE_NAVBAR_REQUEST })
	}
}

export const startVideoPlayer = (video) => async (dispatch) => {
	try {
		dispatch({
			type: START_VIDEO_PLAYER_REQUEST,
		})

		const initialState = {
			navbar: true,
			videoId: video,
			visible: true,
		}

		// console.log('youtubeAction startVideoPlayer: ', { initialState })

		dispatch({
			type: START_VIDEO_PLAYER_SUCCESS,
			payload: initialState,
		})
	} catch (error) {
		dispatch({
			type: START_VIDEO_PLAYER_FAIL,
			payload: { error: 'Something wrong!!! Not able to Play your Video' },
		})
	}
}

export const stopVideoPlayer = () => async (dispatch) => {
	// console.log('youtubeAction stopVideoPlayer: ')
	try {
		dispatch({
			type: STOP_VIDEO_PLAYER_REQUEST,
		})

		const data = {
			navbar: true,
			videoId: '',
			visible: false,
		}

		// console.log('youtubeAction data: ', { data })

		dispatch({
			type: STOP_VIDEO_PLAYER_SUCCESS,
			payload: data,
		})
	} catch (error) {
		dispatch({
			type: STOP_VIDEO_PLAYER_FAIL,
			payload: { error: 'Something wrong!!! Not able to stop your Video' },
		})
	}
}
