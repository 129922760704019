import styled from 'styled-components'

export const InfoContainer = styled.div`
	border-bottom-left-radius: 1.5rem;
	border-bottom-right-radius: 1.5rem;
	position: relative;
	border: 2px solid #fff;
	${'' /* margin-top: 10px; */}

	max-width: 120rem;
	padding: 0 3.2rem;
	margin: 0 auto;

	${
		'' /* background: linear-gradient(cyan, transparent),
		linear-gradient(-45deg, magenta, transparent),
		linear-gradient(45deg, yellow, transparent);
	background-blend-mode: multiply; */
	}

	@media screen and (max-width: 768px) {
		height: 1100px;
	}

	@media screen and (max-width: 480px) {
		height: 1300px;
	}
`

export const Grid2Layout = styled.div`
	display: grid;
	column-gap: 1rem;
	row-gap: 7rem;
	${
		'' /* column-gap: 6.4rem;
	row-gap: 9.6rem; */
	}

	grid-template-columns: 2fr 1fr;
	align-items: center;
`

export const InfoWrapper = styled.div`
	max-width: 1100px;
	margin: 0 auto;
	display: grid;
	grid-template-columns: 3fr 1fr;
	align-items: center;
	justify-content: center;

	@media screen and (max-width: 1000px) {
		grid-template-columns: 1fr 1fr;
	}

	@media screen and (max-width: 768px) {
		grid-template-columns: 1fr;
		padding: 0 20px;
	}
`

export const InfoRow = styled.div`
	display: grid;
	grid-auto-columns: minmax(auto, 1fr);

	align-items: center;
	grid-template-areas: ${({ imgStart }) =>
		imgStart ? ` 'col2 col1' ` : ` 'col1 col2' `};

	@media screen and (max-width: 768px) {
		grid-template-areas: ${({ imgStart }) =>
			imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`};
	}
`

export const Column1 = styled.div`
	${'' /* margin-bottom: 15px; */}
	padding: 0 0;
`

export const Column2 = styled.div`
	margin-bottom: 15px;
	padding: 0 15px;
	grid-area: col2;
`

export const TextWrapper = styled.div`
	max-width: 540px;
	padding-top: 0;
	${'' /* padding-bottom: 60px; */}
`

export const TopLine = styled.p`
	color: #010606;
	font-size: 24px;
	line-height: 16px;
	font-weight: 700;
	letter-spacing: 1.4px;
	text-transform: uppercase;
	margin-bottom: 26px;
	margin-top: 25px;
`

// export const Heading = styled.h1`
// 	margin-bottom: 24px;
// 	font-size: 48px;
// 	line-height: 1.1;
// 	font-weight: 600;
// 	color: ${({ lightText }) => (lightText ? '#f7f8fa' : '#010606')};

// 	@media screen and (max-width: 480px) {
// 		font-size: 32px;
// 	}
// `
export const Heading = styled.h1`
	margin-bottom: 24px;
	font-size: 48px;
	line-height: 1.1;
	font-weight: 600;
	color: ${({ lightText }) => (lightText ? '#f7f8fa' : '#010606')};

	@media screen and (max-width: 480px) {
		font-size: 32px;
	}
`
export const HeaderTitle = styled.h1`
	margin-top: 1.5rem;
	margin-bottom: 0 0;
	font-size: 2.5rem;
	line-height: 1.1;
	font-weight: 600;
	textalign: center;
	color: ${({ lightText }) => (lightText ? '#f7f8fa' : '#010606')};
	padding-bottom: 4rem;

	@media screen and (max-width: 480px) {
		font-size: 1.5rem;
	}
`

export const SubTitle = styled.p`
	font-size: 1.8rem;
	line-height: 1.8;
`
export const SubTitle1 = styled.p`
	max-width: 440px;
	margin-bottom: 35px;
	font-size: 18px;
	font-weight: 700;
	line-height: 24px;
	color: ${({ darkText }) => (darkText ? '#010606' : '#fff')};
`

export const BtnWrap = styled.div`
	display: flex;
	justify-content: flex-start;
`

export const ImgWrap = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	&:before {
		content: '';
		display: block;
		border-radius: 50%;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 60%;

		padding-bottom: 60%;

		background-color: #fdf2e9;

		z-index: -2;
	}
	&:after {
		content: '';
		display: block;
		border-radius: 50%;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 45%;
		padding-bottom: 45%;
		${'' /* background-color: #fae5d3; */}
		background: linear-gradient(cyan, transparent),
		linear-gradient(-45deg, magenta, transparent),
		linear-gradient(45deg, yellow, transparent);
		background-blend-mode: multiply;
		z-index: -1;
	}
`
export const ImgWrap1 = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
`

export const Img = styled.img`
	width: 85%;
	padding: 0 0;
	border-radius: 16px;
`
export const Img1 = styled.img`
	width: 90%;
	padding: 0 0;
	border-radius: 16px;
`

export const InfoSectionLinksContainer = styled.div`
	display: flex;
	justify-content: center;

	@media screen and (max-width: 820px) {
		padding-top: 32px;
	}
`

export const InfoSectionLinksWrapper = styled.div`
	display: flex;

	@media screen and (max-width: 820px) {
		flex-direction: column;
	}
`

export const InfoSectionLinkItems = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin: 16px;
	text-align: left;
	width: 160px;
	box-sizing: border-box;
	color: #fff;

	@media screen and (max-width: 420px) {
		margin: 0;
		padding: 10px;
		width: 100%;
	}
`

export const InfoSectionLinkTitle = styled.h1`
	font-size: 16px;
	margin-bottom: 16px;
	color: #fff;
`
export const InfoSectionLinkColumn = styled.h2`
	font-size: 14px;
	margin-bottom: 0.5rem;
	color: #fff;
`
export const H3heading = styled.h3`
	font-size: 2rem;
	${'' /* line-height: 1.2; */}
	margin-bottom: 1rem;
`

export const AboutTopSection = styled.section`
	padding: 9.6rem 0;
`
