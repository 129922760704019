import React, { useState, useEffect } from 'react'
import { Table, Form, Button, Row, Col } from 'react-bootstrap'
// import { LinkContainer } from 'react-router-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'

import { getUserDetails, updateUserProfile } from '../actions/userActions'
import { listMyOrders } from '../actions/orderActions'

import { USER_UPDATE_PROFILE_RESET } from '../constants/userConstants'

const ProfileScreen = ({ location, history }) => {
	const [name, setName] = useState('')
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [confirmPassword, setConfirmPassword] = useState('')
	const [message, setMessage] = useState('')

	const dispatch = useDispatch()

	//get the user register state
	const userDetails = useSelector((state) => state.userDetails)
	const { loading, error, user } = userDetails

	const userLogin = useSelector((state) => state.userLogin)
	const { userInfo } = userLogin

	const userUpdateProfile = useSelector((state) => state.userUpdateProfile)
	const { success } = userUpdateProfile

	const orderListMy = useSelector((state) => state.orderListMy)
	const { loading: loadingOrders, error: errorOrders, orders } = orderListMy

	//If the user is not logged in then redirect to login page
	useEffect(() => {
		if (!userInfo) {
			history.push('/login')
		} else {
			if (!user || !user.name || success) {
				dispatch({ type: USER_UPDATE_PROFILE_RESET })
				dispatch(getUserDetails('profile'))
				dispatch(listMyOrders())
			} else {
				setName(user.name)
				setEmail(user.email)
			}
		}
	}, [dispatch, history, userInfo, user, success])

	const submitHandler = (e) => {
		e.preventDefault()
		//DISPATCH
		if (password !== confirmPassword) {
			setMessage('Passwords do not match')
		} else {
			//DISPATCH UPDATE PROFILE
			dispatch(updateUserProfile({ id: user._id, name, email, password }))
		}
	}

	return (
		<div style={{ marginTop: '2rem' }}>
			<Row style={{ marginBottom: '5rem' }}>
				<Col md={3}>
					<h2>User Profile</h2>
					{message && <Message variant='danger'>{message}</Message>}
					{error && <Message variant='danger'>{error}</Message>}
					{success && <Message variant='success'>Profile Updated</Message>}
					{loading && <Loader />}

					<Form
						onSubmit={submitHandler}
						style={{ marginTop: '3rem', fontSize: '1.5rem' }}
					>
						<Form.Group controlId='name'>
							<Form.Label>Name </Form.Label>
							<Form.Control
								style={{ fontSize: '1.25rem' }}
								type='Name'
								placeholder='Enter Name'
								value={name}
								onChange={(e) => setName(e.target.value)}
							></Form.Control>
						</Form.Group>

						<Form.Group controlId='email'>
							<Form.Label>Email Address</Form.Label>
							<Form.Control
								style={{ fontSize: '1.25rem' }}
								type='email'
								placeholder='Enter email'
								value={email}
								onChange={(e) => setEmail(e.target.value)}
							></Form.Control>
						</Form.Group>

						<Form.Group controlId='password'>
							<Form.Label>Password</Form.Label>
							<Form.Control
								style={{ fontSize: '1.25rem' }}
								type='password'
								placeholder='Enter password'
								value={password}
								onChange={(e) => setPassword(e.target.value)}
							></Form.Control>
						</Form.Group>

						<Form.Group controlId='confirmPassword'>
							<Form.Label>Confirm Password</Form.Label>
							<Form.Control
								style={{ fontSize: '1.25rem' }}
								type='password'
								placeholder='Confirm password'
								value={confirmPassword}
								onChange={(e) => setConfirmPassword(e.target.value)}
							></Form.Control>
						</Form.Group>

						<Button
							type='submit'
							variant='primary'
							style={{ fontSize: '1.25rem' }}
						>
							Update
						</Button>
					</Form>
				</Col>

				<Col md={9} style={{ fontSize: '1.25rem' }}>
					<h2 style={{ textAlign: 'center' }}>My Donations</h2>
					{loadingOrders ? (
						<Loader />
					) : errorOrders ? (
						<Message variant='danger'>{errorOrders}</Message>
					) : (
						<Table striped bordered hover responsive className='table-sm'>
							<thead>
								<tr>
									<th style={{ fontSize: '1.25rem' }}>SERVICE</th>
									<th style={{ fontSize: '1.25rem' }}>DATE</th>
									<th style={{ fontSize: '1.25rem' }}>TOTAL</th>
									<th style={{ fontSize: '1.25rem' }}>PAYMENT</th>
									{/* <th style={{ fontSize: '1.25rem' }}>DELIVERED</th> */}
									<th></th>
								</tr>
							</thead>
							<tbody>
								{orders.map((order) => (
									<tr key={order._id}>
										<td>{order._id}</td>
										<td>{order.createdAt.substring(0, 10)}</td>
										<td>{order.totalPrice}</td>
										<td>
											{/* {order.isPaid ? (
												order.paidAt.substring(0, 10)
											) : (
												<i
													className='fas fa-times'
													style={{ color: 'red' }}
												></i>
											)} */}
											Credit Card
										</td>
										{/* <td>
											{order.isDelivered ? (
												order.deliveredAt.substring(0, 10)
											) : (
												<i
													className='fas fa-times'
													style={{ color: 'red' }}
												></i>
											)}
										</td> */}
										{/* <td>
											<LinkContainer to={`/order/${order._id}`}>
												<Button className='btn-sm' variant='light'>
													Details
												</Button>
											</LinkContainer>
										</td> */}
									</tr>
								))}
							</tbody>
						</Table>
					)}
				</Col>
			</Row>
		</div>
	)
}

export default ProfileScreen
