import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import FormContainer from '../components/FormContainer'
import { forgotPass } from '../actions/userActions'
import {
	LoginContainer,
	H1heading,
	H2heading,
	StatusMessages,
	Img,
	ResetPassButton,
	SignInLinkButton,
} from './SignInScreenElements'
import SaiBhagawan from '../assests/Ganapathi-modified.png'
// import { ReactComponent as ShoppingBag } from '../assests/shopping-bag.svg'

const ForgotPassScreen = ({ location, history }) => {
	const [email, setEmail] = useState('')
	// const [name, setName] = useState('')

	const dispatch = useDispatch()

	const userLogin = useSelector((state) => state.userLogin)

	const { loading, error, userInfo } = userLogin

	const redirect = location.search ? location.search.split('=')[1] : '/'

	useEffect(() => {
		if (userInfo) {
			history.push(redirect)
		}
	}, [history, userInfo, redirect])

	const submitHandler = (e) => {
		e.preventDefault()
		//DISPATCH LOGIN
		dispatch(forgotPass(email))
	}

	return (
		<>
			<LoginContainer>
				<div
					style={{
						textAlign: 'center',
						marginTop: '3rem',
					}}
				>
					<Img src={SaiBhagawan} alt='infosection1' />
				</div>
				<FormContainer>
					<H1heading>PASSWORD RESET</H1heading>
					<StatusMessages>
						{error && <Message variant='danger'>{error}</Message>}
					</StatusMessages>

					{loading && <Loader />}

					<Form onSubmit={submitHandler}>
						<H2heading>
							<Form.Group controlId='email'>
								<Form.Label>Email Address</Form.Label>

								<Form.Control
									style={{ fontSize: '1.4rem', border: '1px solid black' }}
									type='email'
									placeholder='Enter your email id'
									value={email}
									required
									onChange={(e) => setEmail(e.target.value)}
								></Form.Control>
							</Form.Group>
						</H2heading>
						{/* <Button
							type='submit'
							variant='primary'
							style={{
								fontSize: '1.4rem',
								marginLeft: '20rem',
								marginTop: '1rem',
								marginBottom: '2rem',
							}}
						>
							Reset Password
						</Button> */}
						<Row>
							<Col>
								<ResetPassButton>Reset Password</ResetPassButton>
							</Col>
						</Row>
						<div style={{ display: 'flex', justifyContent: 'center' }}>
							<Row
								style={{
									marginTop: '2rem',
									marginBottom: '3rem',
								}}
							>
								{/* <Col style={{ fontSize: '1.5rem' }}>
									New User? &nbsp;&nbsp;&nbsp;
									<Link
										style={{
											color: '#fff',
											fontSize: '1.5rem',
											backgroundColor: '#e67e22',
											padding: '1rem',
											borderRadius: '.5rem',
											textDecoration: 'none',
										}}
										to={
											redirect ? `/register?redirect=${redirect}` : '/register'
										}
									>
										Register
									</Link>
								</Col> */}

								<Col style={{ fontSize: '1.5rem' }}>
									New User? &nbsp;&nbsp;&nbsp;
									<SignInLinkButton>
										<Link
											style={{
												color: 'green',
												fontWeight: 'bold',
												textDecoration: 'none',
												fontSize: '1.4rem',
											}}
											to={
												redirect
													? `/register?redirect=${redirect}`
													: '/register'
											}
										>
											Register
										</Link>
									</SignInLinkButton>
								</Col>
							</Row>
						</div>
					</Form>
				</FormContainer>
			</LoginContainer>
		</>
	)
}

export default ForgotPassScreen
