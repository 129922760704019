import React from 'react'

import SaiBhagawan from '../assests/Saibaba9.jpg'
import './styles.css'

import {
	InfoContainer,
	FirstColDiv,
	H1Title,
	SubTitle2,
	ImageWrap,
	Image,
	Grid2Layout,
} from './PoojaTimingsElements'

const InfoSection2 = () => {
	return (
		<>
			<InfoContainer>
				<H1Title lightText={true}> 🕉 Pooja Timings 🕉 </H1Title>
				<Grid2Layout>
					<FirstColDiv>
						<SubTitle2>
							<strong>Monday - Friday (Central Standard Time) </strong>
							<ul style={{ paddingLeft: '2rem', listStyleType: 'none' }}>
								<li>Kakad Arthi: 7:00 AM ☸ Abhishekam: 7:30 AM</li>
								<li>Madhyahna Aarthi: 12:00 PM</li>
								<li>Sayanthra Aarthi: 6:00 PM</li>
								<li>Sej Arthi: 7:30 PM</li>
							</ul>
						</SubTitle2>
						<SubTitle2>
							<strong>Every Monday (CST) </strong>
							<ul style={{ paddingLeft: '2rem', listStyleType: 'none' }}>
								<li>Rudrabhishekam: 6:30 PM</li>
							</ul>
						</SubTitle2>
						<SubTitle2>
							<strong>Every Thursday (CST) </strong>
							<ul style={{ paddingLeft: '2rem', listStyleType: 'none' }}>
								<li>Sai Bhajans: 7:00 PM - 8:00 PM</li>
								<li>Sai Pallaki Seva: 7:30 PM - 8:00 PM</li>
							</ul>
						</SubTitle2>
						<SubTitle2>
							<strong>Every Friday (CST) </strong>
							<ul style={{ paddingLeft: '2rem', listStyleType: 'none' }}>
								<li>Lalitha Sahasranama Parayanm: 6:30 PM </li>
							</ul>
						</SubTitle2>
						<SubTitle2>
							<strong>Every Saturday & Sunday (CST) </strong>
							<ul style={{ paddingLeft: '2rem', listStyleType: 'none' }}>
								<li>Saibaba Abhishekam: 9:00 AM </li>
							</ul>
						</SubTitle2>
					</FirstColDiv>
					<ImageWrap>
						<Image src={SaiBhagawan} alt='infosection1' />
					</ImageWrap>
				</Grid2Layout>
			</InfoContainer>
		</>
	)
}

export default InfoSection2
