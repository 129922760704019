// import logo from './logo.svg';
// import './App.css';
import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'

import Header from './components/Header'
import Footer from './components/Footer'
import Banner from './components/Banner'
//Container will give alignment with left and right margins.
import { Container } from 'react-bootstrap'
import HomeScreen from './screens/HomeScreen'
import AdminHomeScreen from './screens/AdminHomeScreen'
import ProductScreen from './screens/ProductScreen'
import CartScreen from './screens/CartScreen'
import LoginScreen from './screens/SignInScreen'
import ForgotPassScreen from './screens/ForgotPassScreen'
import ResetPassScreen from './screens/ResetPassScreen'
import RegisterScreen from './screens/RegisterScreen'
import ProfileScreen from './screens/ProfileScreen'
import ShippingScreen from './screens/ShippingScreen'
import PaymentScreen from './screens/PaymentScreen'
import { PlaceOrderScreen } from './screens/PlaceOrderScreen'
import { PlaceOrderByCheckScreen } from './screens/PlaceOrderByCheckScreen'
import OrderScreen from './screens/OrderScreen'
import UserListScreen from './screens/UserListScreen'
import UserEditScreen from './screens/UserEditScreen'
import UserAddScreen from './screens/UserAddScreen'
import ProductListScreen from './screens/ProductListScreen'
import ProductEditScreen from './screens/ProductEditScreen'
import OrderListScreen from './screens/OrderListScreen'
import PoojaScreen from './screens/PoojaScreen'
import DeitiesScreen from './screens/DeitiesScreen'
import WishListScreen from './screens/WishListScreen'
import FestivalScreen from './screens/FestivalScreen'
import MembershipScreen from './screens/MembershipScreen'
import PoojaShopScreen from './screens/PoojaShopScreen'
import PriestScreen from './screens/PriestScreen'
import RentalsScreen from './screens/RentalsScreen'
import KitchenScreen from './screens/KitchenScreen'
import AboutScreen from './screens/AboutScreen'
import { SuccessScreen } from './screens/SuccessScreen'
// import NotFoundScreen from './screens/NotFoundScreen'

//This is an example of using functional components. Below code is changed
//to arrow function which means change the name function to const and arrow
//as shown.
//function App() {
const App = () => {
	//Important thing to remember is you can have only one element.
	//For example you can't have more than one div. It doesn't have to be
	//only div it can be any other element but it has to be the only element
	//rest of the code wrapped inside. You can also use fragment <> and change
	//div to h1 as shown below.

	return (
		<Router>
			<Banner />
			{/* fixed position sticky navbar */}
			<div
				style={{
					position: 'sticky',
					top: 0,
					zIndex: 100,
					marginBottom: '.5rem',
					marginTop: '.5rem',
				}}
			>
				<Header />
			</div>
			{/* <main className='py-3'> */}
			<Container>
				<Route path='/about' component={AboutScreen} exact />
				<Route path='/donate' component={PoojaScreen} exact />
				<Route path='/pooja' component={PoojaScreen} exact />
				<Route path='/deities' component={DeitiesScreen} exact />
				<Route path='/wishlist' component={WishListScreen} exact />
				<Route path='/festival' component={FestivalScreen} exact />
				<Route path='/membership' component={MembershipScreen} exact />
				<Route path='/poojashop' component={PoojaShopScreen} exact />
				<Route path='/priest' component={PriestScreen} exact />
				<Route path='/rentals' component={RentalsScreen} exact />
				<Route path='/kitchen' component={KitchenScreen} exact />
				<Route path='/order/:id' component={OrderScreen} />
				<Route path='/shipping' component={ShippingScreen} />
				<Route path='/payment' component={PaymentScreen} />
				<Route path='/success' component={SuccessScreen} />
				<Route path='/placeorder' component={PlaceOrderScreen} />
				<Route path='/placecheckorder' component={PlaceOrderByCheckScreen} />
				<Route path='/login' component={LoginScreen} />
				<Route path='/forgotpass' component={ForgotPassScreen} />
				<Route path='/resetpass/:token?' component={ResetPassScreen} />
				<Route path='/register' component={RegisterScreen} />
				<Route path='/newsletter' component={HomeScreen} />
				<Route path='/profile' component={ProfileScreen} />
				<Route path='/product/:id' component={ProductScreen} />
				<Route path='/cart/:id?' component={CartScreen} />
				<Route path='/admin/userlist' component={UserListScreen} />
				<Route path='/admin/user/:id/edit' component={UserEditScreen} />
				<Route path='/admin/user/:id/add' component={UserAddScreen} />
				<Route path='/admin/productlist' component={ProductListScreen} exact />
				<Route
					path='/admin/productlist/:pageNumber'
					component={ProductListScreen}
					exact
				/>
				<Route path='/admin/product/:id/edit' component={ProductEditScreen} />
				<Route path='/admin/orderlist' component={OrderListScreen} />
				<Route path='/search/:keyword' component={HomeScreen} exact />
				<Route path='/page/:pageNumber' component={HomeScreen} exact />
				<Route
					path='/search/:keyword/page/:pageNumber'
					component={HomeScreen}
					exact
				/>
				{/* <Route path='/notfound' component={NotFoundScreen} /> */}
				<Route path='/home' component={HomeScreen} />
				<Route path='/adminhome' component={AdminHomeScreen} />
				<Route path='/' component={HomeScreen} exact />
				{/* <Redirect to='/notfound' /> */}
			</Container>
			{/* </main> */}
			<Footer />
		</Router>
	)
}

export default App
//On the above line where you see ? for the cartscreen means id is optional
