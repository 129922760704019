import styled from 'styled-components'

export const LoginContainer = styled.div`
	border-radius: 2rem;
	position: relative;
	max-width: 120rem;
	border: 2px solid #fff;

	${
		'' /* background: linear-gradient(cyan, transparent),
		linear-gradient(-45deg, magenta, transparent),
		linear-gradient(45deg, yellow, transparent);  */
	}

	background:
	linear-gradient(
		limegreen,
		transparent
	),
	linear-gradient(
		90deg,
		skyblue,
		transparent
	),
	linear-gradient(
		-90deg,
		coral,
		transparent
	);

	background-blend-mode: screen;

	@media screen and (max-width: 768px) {
		padding: 100px 0;
	}
`

export const RegisterContainer = styled.div`
	border-radius: 2rem;
	position: relative;
	border: 2px solid #fff;
	max-width: 120rem;

	${
		'' /* background: #db79ad;
	background-image: linear-gradient(
		90deg,
		#fff7f3,
		#fde4e1,
		#fccfcc,
		#fbb5bc,
		#f993b0,
		#f369a3,
		#e03f98,
		#c11889,
		#99037c,
		#710174,
		#710174
	); */
	}
	${'' /* background-blend-mode: multiply; */}

    background:
	linear-gradient(
		limegreen,
		transparent
	),
	linear-gradient(
		90deg,
		skyblue,
		transparent
	),
	linear-gradient(
		-90deg,
		coral,
		transparent
	);

	background-blend-mode: screen;

	@media screen and (max-width: 768px) {
		padding: 100px 0;
	}
`
export const H1heading = styled.h1`
	margin-top: 0.5rem;
	${'' /* margin-bottom: 1rem; */}
	text-align: center;
	font-size: 2.2rem;
`
export const H2heading = styled.h2`
	padding-bottom: 0.5rem;
`
export const H3heading = styled.h3`
	padding-bottom: 1rem;
	text-align: center;
`
export const SubTitle2 = styled.p`
	font-size: 1.8rem;
	line-height: 1.8;
	${'' /* padding-left: 1rem; */}
	color: #010606;
`
export const StatusMessages = styled.h2`
	font-size: 1.5rem;
`
export const TopLine = styled.p`
	color: #010606;
	font-size: 24px;
	line-height: 16px;
	font-weight: 700;
	letter-spacing: 1.4px;
	text-transform: uppercase;
	margin-bottom: 26px;
	margin-top: 25px;
`
export const ImgWrap = styled.div`
	max-width: 30%;
	height: 30%;
	justify-content: 'center';
	align-items: 'center';
`

export const Img = styled.img`
	width: 7%;

	${
		'' /* &:hover {
		border-radius: 0px;
		width: 450px;
		height: auto;

		box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04),
			0px 2px 6px rgba(9, 55, 53, 0.08), 0px 16px 24px rgba(9, 55, 53, 0.1),
			0px 24px 32px rgba(9, 55, 53, 0.14);
	} */
	}
`
export const SignInButton = styled.button`
	width: 12rem;
	height: 4rem;
	margin-top: 2rem;
	background: black;
	color: white;
	border: none;
	border-radius: 0.5rem;
	font-size: 1.7rem;
	font-weight: bold;
	cursor: pointer;
	position: relative;
	z-index: 1;
	overflow: hidden;
	margin-left: 40%;

	&:hover {
		background-image: linear-gradient(
			90deg,
			#53cbef 0%,
			#dcc66c 50%,
			#ffa3b6 75%,
			#53cbef 100%
		);
		animation: slidernbw 5s linear infinite;
		color: #010606;
	}

	@keyframes slidernbw {
		to {
			background-position: 20vw;
		}
	}
`

export const ResetPassButton = styled.button`
	width: 12rem;
	height: 4rem;
	margin-bottom: 1.5rem;
	background: black;
	color: white;
	border: none;
	border-radius: 0.5rem;
	font-size: 1.4rem;
	font-weight: bold;
	cursor: pointer;
	position: relative;
	z-index: 1;
	overflow: hidden;
	margin-left: 40%;

	&:hover {
		background-image: linear-gradient(
			90deg,
			#53cbef 0%,
			#dcc66c 50%,
			#ffa3b6 75%,
			#53cbef 100%
		);
		animation: slidernbw 5s linear infinite;
		color: #010606;
	}

	@keyframes slidernbw {
		to {
			background-position: 20vw;
		}
	}
`
export const SignUpButton = styled.button`
	width: 12rem;
	height: 4rem;
	margin-top: 2rem;
	background: black;
	color: white;
	border: none;
	border-radius: 0.5rem;
	font-size: 1.7rem;
	font-weight: bold;
	cursor: pointer;
	position: relative;
	z-index: 1;
	overflow: hidden;
	margin-left: 3%;

	&:hover {
		color: #010606;
		border: 2px solid #010606;
		background: #e1e1e1;
	}

	&:after {
		content: '';
		background: white;
		position: absolute;
		z-index: -1;
		left: -20%;
		right: -20%;
		top: 0;
		bottom: 0;
		transform: skewX(-45deg) scale(0, 1);
		transition: all 0.5s;
	}

	&:hover:after {
		transform: skewX(-45deg) scale(1, 1);
		-webkit-transition: all 0.5s;
		transition: all 0.5s;
	}
`
export const SignInLinkButton = styled.button`
	width: 12rem;
	height: 4rem;
	margin-top: 2rem;
	background: black;
	color: white;
	border: none;
	border-radius: 0.5rem;
	font-size: 1.7rem;
	font-weight: bold;
	cursor: pointer;
	position: relative;
	z-index: 1;
	overflow: hidden;
	${'' /* margin-left: -20%; */}

	&:hover {
		color: #010606;
		border: 2px solid #010606;
		background: #e1e1e1;
	}

	&:after {
		content: '';
		background: white;
		position: absolute;
		z-index: -1;
		left: -20%;
		right: -20%;
		top: 0;
		bottom: 0;
		transform: skewX(-45deg) scale(0, 1);
		transition: all 0.5s;
	}

	&:hover:after {
		transform: skewX(-45deg) scale(1, 1);
		-webkit-transition: all 0.5s;
		transition: all 0.5s;
	}
`
