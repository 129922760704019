import React from 'react'
// import { Route } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../actions/userActions'
import sailogo from '../assests/saidhamlogo.png'
import './styles.css'
// import CartIcon from './cartIcon/CartIcon'
// import SearchBox from './SearchBox'
// import shoppingBag from '../assests/shoppingBag1.jpg'
import { HiShoppingCart } from 'react-icons/hi'

const Header = () => {
	const dispatch = useDispatch()

	const userLogin = useSelector((state) => state.userLogin)
	const { userInfo } = userLogin

	const cart = useSelector((state) => state.cart)
	const { cartItems } = cart

	//calculates the total number of items in the cart.
	let totalQuantity = cartItems.reduce(
		(acc, item) => acc + item.cartQuantity,
		0
	)

	const logoutHandler = () => {
		dispatch(logout())
	}
	return (
		<Container>
			{/* <header> */}
			<Navbar collapseOnSelect bg='dark' variant='dark' expand='md'>
				<Container>
					<LinkContainer to='/'>
						<Navbar.Brand className='bootNavBarLogo'>
							<img
								src={sailogo}
								alt='gamahsoft'
								style={{
									width: 100,
								}}
							/>
							{/* Sai <span style={{ color: '#e67e22' }}>✡🔯🕉☸✡</span> Dham */}
							Sai{' '}
							<span
								style={{
									color: '#e67e22',
									// fontSize: '2.5rem',
								}}
							>
								✡
							</span>{' '}
							Dham
						</Navbar.Brand>
					</LinkContainer>
					<Navbar.Toggle aria-controls='responsive-navbar-nav' />

					<Navbar.Collapse id='responsive-navbar-nav'>
						{/* {userInfo && userInfo.isAdmin && (
								<Route
									render={({ history }) => <SearchBox history={history} />}
								/>
							)} */}
						<Nav className='ml-auto'>
							<LinkContainer to='/home' className='bootNavBar'>
								<Nav.Link>Home</Nav.Link>
							</LinkContainer>

							<LinkContainer to='/about' className='bootNavBar'>
								<Nav.Link>About</Nav.Link>
							</LinkContainer>

							<NavDropdown
								title='Services'
								id='collasible-nav-dropdown'
								className='bootNavBar'
							>
								<NavDropdown.Item className='bootNavBar' href='/pooja'>
									Sai Baba
								</NavDropdown.Item>
								<NavDropdown.Item className='bootNavBar' href='/deities'>
									All Deities
								</NavDropdown.Item>
								<NavDropdown.Item className='bootNavBar' href='/wishlist'>
									Wish List
								</NavDropdown.Item>
								{/* <NavDropdown.Item className='bootNavBar' href='/festivals'>
										Festivals
									</NavDropdown.Item> */}
								<NavDropdown.Item className='bootNavBar' href='/membership'>
									Membership
								</NavDropdown.Item>
								<NavDropdown.Item className='bootNavBar' href='/priest'>
									Priest
								</NavDropdown.Item>
								<NavDropdown.Item className='bootNavBar' href='/rentals'>
									Rentals
								</NavDropdown.Item>
								<NavDropdown.Divider />
								<NavDropdown.Item className='bootNavBar' href='/poojashop'>
									Pooja Items
								</NavDropdown.Item>
								<NavDropdown.Item className='bootNavBar' href='/kitchen'>
									Kitchen
								</NavDropdown.Item>
							</NavDropdown>
							{/* <CartIcon /> */}

							<LinkContainer to='/pooja' className='bootNavBar'>
								<Nav.Link>Donate</Nav.Link>
							</LinkContainer>

							{userInfo ? (
								<NavDropdown
									title={userInfo.name}
									id='username'
									className='bootNavBar'
								>
									<LinkContainer to='/profile' className='bootNavBar'>
										<NavDropdown.Item>Profile</NavDropdown.Item>
									</LinkContainer>
									<NavDropdown.Item
										onClick={logoutHandler}
										className='bootNavBar'
									>
										Logout
									</NavDropdown.Item>
								</NavDropdown>
							) : (
								<LinkContainer to='/login' className='bootNavBar'>
									<Nav.Link>
										{/* <i className='fas fa-user'></i> */}
										Sign In
									</Nav.Link>
								</LinkContainer>
							)}

							{userInfo && userInfo.isAdmin && (
								<NavDropdown
									title='Admin'
									id='adminmenu'
									style={{ fontSize: '1rem' }}
								>
									<LinkContainer to='/admin/userlist' className='bootNavBar'>
										<NavDropdown.Item>Users</NavDropdown.Item>
									</LinkContainer>
									<LinkContainer to='/admin/productlist' className='bootNavBar'>
										<NavDropdown.Item>Services</NavDropdown.Item>
									</LinkContainer>
									<LinkContainer to='/admin/orderlist' className='bootNavBar'>
										<NavDropdown.Item>Donations</NavDropdown.Item>
									</LinkContainer>
								</NavDropdown>
							)}
						</Nav>
					</Navbar.Collapse>

					<LinkContainer to='/cart'>
						{/* <Navbar.Brand className='bootNavBarLogo'> */}
						<Navbar.Brand>
							{/* <img
									src={shoppingBag}
									alt='shopping bag'
									style={{
										width: '8rem',
										// marginRight: 0,
										// paddingRight: 0,
										borderRadius: '60%',
										objectFit: 'cover',
									}}
								/> */}
							<h3
								style={{
									color: '#fff',
									fontSize: '4.5rem',
									// marginRight: '0',
									// paddingRight: 0,
								}}
							>
								{' '}
								<HiShoppingCart />
								{totalQuantity > 0 ? (
									<span
										style={{
											// marginRight: '-25px',
											marginLeft: '-3rem',
											backgroundColor: 'black',
											marginTop: '-1rem',
											color: 'white',
											fontSize: '1.5rem',
											borderRadius: '50%',
											overflow: 'hidden',
											transition: 'transform .2s',
										}}
									>
										{totalQuantity}
									</span>
								) : null}
							</h3>

							{/* <CartIcon /> */}
						</Navbar.Brand>
					</LinkContainer>
				</Container>
			</Navbar>
			{/* </header> */}
		</Container>
	)
}

export default Header
