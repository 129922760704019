import React, { useEffect, useState } from 'react'
import { Button, Row, Col, ListGroup, Image, Card } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
// import CheckoutSteps from '../components/CheckoutSteps'
import { createOrder } from '../actions/orderActions'
import { paymentIntent } from '../actions/stripeActions'
// import { ORDER_CREATE_RESET } from '../constants/orderConstants'
// import { USER_DETAILS_RESET } from '../constants/userConstants'
// import { PlaceOrderInputScreen } from './PlaceOrderInputScreen'
import './PlaceOrderScreenElements.css'

//Stripe code
import {
	CardNumberElement,
	CardExpiryElement,
	CardCvcElement,
	useStripe,
	useElements,
} from '@stripe/react-stripe-js'

//This card styling is from stripe custom card documentation
const cardStyle = {
	style: {
		base: {
			color: '#424770',
			fontFamily: 'Roboto, sans-serif',
			fontSmoothing: 'antialiased',
			fontSize: '16px',
			'::placeholder': {
				color: '#aab7c4',
			},
		},
		invalid: {
			color: '#fa755a',
			iconColor: '#fa755a',
		},
	},
}

export const PlaceOrderScreen = ({ history: { push } }) => {
	let donorName
	let donorEmail

	const [processing, setProcessing] = useState(false)
	const [error, setError] = useState(null)
	// const [clientSecret, setClientSecret] = useState(null)
	const stripe = useStripe()
	const elements = useElements()
	// const options = useOptions()
	const dispatch = useDispatch()

	// var element = stripe.elements()

	const cart = useSelector((state) => state.cart)
	const userLogin = useSelector((state) => state.userLogin)
	const { userInfo } = userLogin

	//Admin user taking payments
	const userSelect = useSelector((state) => state.userSelect)

	const { userSelected } = userSelect

	const { userID, name, email } = userSelected

	// const userSelected = useSelector((state) => state.userCreate)

	// const { name, email } = userSelected

	// get details about the order creation is successfull or not
	const orderCreate = useSelector((state) => state.orderCreate)
	const { success } = orderCreate

	const stripeIntent = useSelector((state) => state.stripeIntent)
	const { stripeKey } = stripeIntent

	//If the logged in user is admin then get the selected user details
	if (userInfo && userInfo.isAdmin) {
		donorName = name
		donorEmail = email
	} else {
		donorName = userInfo.name
		donorEmail = userInfo.email
	}

	if (!cart.paymentMethod) {
		// history.push('/payment')
		cart.paymentMethod = 'CreditCard'
	}

	//   Calculate prices
	const addDecimals = (num) => {
		return (Math.round(num * 100) / 100).toFixed(2)
	}

	useEffect(() => {
		if (!stripe) {
			return
		}
		const items = cart.cartItems.map((item) => ({
			price: item.product.price,
			quantity: item.cartQuantity,
		}))

		if (items.length > 0) {
			const body = {
				cartItems: items,
				description: 'Payment intent',
				name: donorName,
				receipt_email: donorEmail,
			}

			dispatch(paymentIntent(body))

			// setClientSecret(clientSecret)
		}
	}, [stripe, cart.cartItems, dispatch, donorEmail, donorName])

	const cardHandleChange = (event) => {
		const { error } = event
		setError(error ? error.message : '')
	}

	// Create Order in the backend
	const placeOrderHandler = () => {
		dispatch(
			createOrder({
				orderItems: cart.cartItems,
				// shippingAddress: cart.shippingAddress,
				paymentMethod: cart.paymentMethod,
				userID: userID,
				// itemsPrice: cart.itemsPrice,
				// shippingPrice: cart.shippingPrice,
				// taxPrice: cart.taxPrice,
				// totalPrice: cart.totalPrice,
			})
		)
	}

	const checkoutHandler = async () => {
		// We don't want to let default form submission happen here,
		// which would refresh the page.
		// event.preventDefault()

		if (!stripe || !elements) {
			// Stripe.js has not yet loaded.
			// Make  sure to disable form submission until Stripe.js has loaded.
			return
		}

		setProcessing(true)

		const payload = await stripe.confirmCardPayment(stripeKey.clientSecret, {
			payment_method: {
				card: elements.getElement(CardNumberElement),
			},
		})

		if (payload.error) {
			setError(`Payment Failed: ${payload.error.message}`)
			setProcessing(false)
		} else {
			//call create order action
			placeOrderHandler()

			// if (success) {
			setProcessing(false)
			push('/success')
			// }
			// return
		}
	}

	const cartTotalPrice = addDecimals(
		cart.cartItems.reduce(
			(acc, item) => acc + item.product.price * item.cartQuantity,
			0
		)
	)
	// cart.shippingPrice = addDecimals(cart.itemsPrice > 100 ? 0 : 100)
	// cart.taxPrice = addDecimals(Number((0.15 * cart.itemsPrice).toFixed(2)))
	cart.shippingPrice = 0
	cart.taxPrice = 0

	cart.totalPrice = (
		Number(cart.itemsPrice) +
		Number(cart.shippingPrice) +
		Number(cart.taxPrice)
	).toFixed(2)

	// const orderCreate = useSelector((state) => state.orderCreate)
	// const { order, success, error } = orderCreate

	// useEffect(() => {
	// 	if (success) {
	// 		history.push(`/order/${order._id}`)
	// 		dispatch({ type: USER_DETAILS_RESET })
	// 		dispatch({ type: ORDER_CREATE_RESET })
	// 	}
	// eslint-disable-next-line
	// }, [history, success])

	return (
		<React.Fragment>
			<>
				{/* <CheckoutSteps step1 step2 step3 step4 /> */}
				<Row style={{ marginTop: '2rem' }}>
					<Col md={7}>
						<ListGroup variant='flush'>
							{/* <ListGroup.Item>
							<h2>Shipping</h2>
							<p>
								<strong>Address:</strong> */}
							{/* {cart.shippingAddress.address}, {cart.shippingAddress.city}{' '}
								{cart.shippingAddress.postalCode},{' '}
								{cart.shippingAddress.country} */}
							{/* </p>
						</ListGroup.Item> */}

							<ListGroup.Item>
								<h2>Payment Method:</h2>

								<strong style={{ fontSize: '1.25rem' }}>
									{cart.paymentMethod}{' '}
								</strong>
							</ListGroup.Item>

							<ListGroup.Item>
								<h2>Order Items:</h2>
								{cart.cartItems.length === 0 ? (
									<Message>Your cart is empty</Message>
								) : (
									<ListGroup variant='flush'>
										{cart.cartItems.map((item, index) => (
											<ListGroup.Item key={index}>
												<Row>
													<Col md={2}>
														<Image
															style={{ width: '100%', height: 'auto' }}
															src={item.product.image}
															alt={item.product.name}
															fluid
															rounded
														/>
													</Col>
													<Col style={{ fontSize: '1.3rem' }}>
														{/* <Link to={`/product/${item.product}`}> */}
														{item.product.name}
														{/* </Link> */}
													</Col>
													<Col md={4} style={{ fontSize: '1.3rem' }}>
														{item.cartQuantity} x ${item.product.price} = $
														{item.cartQuantity * item.product.price}
													</Col>
												</Row>
											</ListGroup.Item>
										))}
									</ListGroup>
								)}
							</ListGroup.Item>
						</ListGroup>
					</Col>
					<Col md={5}>
						{/* ----------- */}

						<Card style={{ backgroundColor: '#87bbfd' }}>
							<ListGroup variant='flush'>
								<ListGroup.Item>
									<h2>Enter Credit Card Details</h2>
								</ListGroup.Item>
								<ListGroup.Item>
									<Row>
										<Col style={{ fontSize: '1.25rem' }}>
											Credit Card Number:
										</Col>
										<Col>
											<CardNumberElement
												className='card-element'
												options={cardStyle}
												onChange={cardHandleChange}
											/>
										</Col>
									</Row>
								</ListGroup.Item>
								<ListGroup.Item>
									<Row className='stripe-card'>
										<Col style={{ fontSize: '1.25rem' }}>Card Expiry Date:</Col>
										<Col>
											<CardExpiryElement
												className='card-element'
												options={cardStyle}
												onChange={cardHandleChange}
											/>
										</Col>
									</Row>
								</ListGroup.Item>
								<ListGroup.Item>
									<Row>
										<Col style={{ fontSize: '1.25rem' }}>CVC Code:</Col>
										<Col>
											<CardCvcElement
												className='card-element'
												options={cardStyle}
												onChange={cardHandleChange}
											/>
										</Col>
									</Row>
								</ListGroup.Item>

								<ListGroup.Item>
									<Button
										style={{
											color: '#fff',
											fontSize: '1.2rem',
											paddingTop: '15px',
											paddingBottom: '15px',
											opacity: 1,
										}}
										type='button'
										className='btn-block'
										onClick={() => checkoutHandler()}
										disabled={cart.cartItems.length <= 0 || processing}
									>
										{processing
											? 'PROCESSING ...'
											: ` PAY  $${cartTotalPrice} `}
									</Button>
								</ListGroup.Item>
								<ListGroup.Item>
									{error && <p className='error-message'>{error}</p>}
								</ListGroup.Item>
							</ListGroup>
						</Card>

						{/* ------------------------------------------ */}

						{/* <h4 className='d-flex justify-content-between align-items-center mb-3'>
							<span className='text-muted'>Pay with card</span>
						</h4>
						<form onSubmit={checkoutHandler}>
							<div className='form-group row'>
								<div className='col-md-12 mb-3'>
									<label htmlFor='cardNumber'>Card Number</label>
									<CardNumberElement
										id='cardNumber'
										className='form-control'
										options={cardStyle}
										onChange={cardHandleChange}
									/>
								</div>
							</div>

							<div className='form-group row'>
								<div className='col-md-6 mb-3'>
									<label htmlFor='expiry'>Expiration Date</label>
									<CardExpiryElement
										id='expiry'
										className='form-control'
										options={cardStyle}
										onChange={cardHandleChange}
									/>
								</div>
								<div className='col-md-6 mb-3'>
									<label htmlFor='cvc'>CVC</label>
									<CardCvcElement
										id='cvc'
										className='form-control'
										options={cardStyle}
										onChange={cardHandleChange}
									/>
								</div>
							</div>
							<button
								disabled={cart.cartItems === 0 || { processing }}
								onClick={checkoutHandler}
							>
								{processing ? 'PROCESSING' : ` PAY $${cartTotalPrice} `}
							</button>
						</form> */}

						{/* -------------------------- */}

						{/* <h4 className='d-flex justify-content-between align-items-center mb-3'>
							<span className='text-muted'>Pay with card</span>
						</h4>

						<Form onSubmit={checkoutHandler}>
							<div className='card-group row'>
								<Form.Group
									controlId='card-number-element'
									className='card-field'
								>
									<Form.Label>Card Number</Form.Label>
									<Form.Control
										style={{ fontSize: '1.4rem' }}
										type='text'
										placeholder='Card Number'
										// value={cardNumberElement}
										required
										// onChange={(e) => cardNumberElement(e.target.value)}
									></Form.Control>
								</Form.Group>
							</div>
							<div className='card-group row'>
								<Form.Group controlId='card-expiry-date' className='card-field'>
									<Form.Label>Expiry Date</Form.Label>

									<Form.Control
										style={{ fontSize: '1.4rem' }}
										type='text'
										placeholder='Expiry Date'
										// value={cardNumberElement}
										required
										// onChange={(e) => cardNumberElement(e.target.value)}
									></Form.Control>
								</Form.Group>
								<Form.Group controlId='card-cvc' className='card-field'>
									<Form.Label>CVC</Form.Label>

									<Form.Control
										style={{ fontSize: '1.4rem' }}
										type='text'
										placeholder='CVC'
										// value={cardNumberElement}
										required
										// onChange={(e) => cardNumberElement(e.target.value)}
									></Form.Control>
								</Form.Group>
							</div>
							<button
								disabled={cart.cartItems === 0 || { processing }}
								onClick={checkoutHandler}
							>
								{processing ? 'PROCESSING' : ` PAY $${cartTotalPrice} `}
							</button>
						</Form> */}

						{/* ------------ */}
					</Col>
				</Row>
			</>
		</React.Fragment>
	)
}

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY)

// export const PlaceOrderScreen = ({ history: { push } }) => {
// 	return (
// 		<Elements stripe={loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY)}>
// 			<PlaceOrder />
// 			push('/success')
// 		</Elements>
// 	)
// }

// export const PlaceOrderScreen = () => {
// 	return (
// 		<Elements stripe={stripePromise}>
// 			<PlaceOrder />
// 		</Elements>
// 	)
// }
