import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { stopVideoPlayer } from '../../actions/youtubeActions'
import Draggable from 'react-draggable'
import { ResizableBox } from 'react-resizable'
import 'react-resizable/css/styles.css'
import './youtubeStyles.css'

export const YoutubePlayer = () => {
	//Bring in orderList from store
	const yourVideo = useSelector((state) => state.youtubeVideo)

	const { initialState } = yourVideo

	const dispatch = useDispatch()

	if (!initialState) {
		return null
	}

	if (initialState) {
		const { visible } = initialState
		// console.log('visible: ', visible)
		if (visible === false) {
			return null
		}
	}

	return (
		<Draggable handle='.handle'>
			<div className='custom-youtube-player'>
				<ResizableBox width={450} height={300}>
					<iframe
						title='123'
						id='player'
						type='text/html'
						style={{ width: '100%', height: '100%' }}
						src={`https://www.youtube.com/embed/${initialState.videoId}`}
						frameBorder='0'
					></iframe>

					<div
						className='close d-flex justify-content-center'
						onClick={() => dispatch(stopVideoPlayer())}
					>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							class='h-6 w-6'
							fill='none'
							viewBox='0 0 24 24'
							stroke='currentColor'
						>
							<path
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeWidth='2'
								d='M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z'
							/>
						</svg>
					</div>
					<div className='handle d-flex justify-content-center'>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							class='h-6 w-6'
							fill='none'
							viewBox='0 0 24 24'
							stroke='currentColor'
						>
							<path
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeWidth='2'
								d='M4 8V4m0 0h4M4 4l5 5m11-1V4m0 0h-4m4 0l-5 5M4 16v4m0 0h4m-4 0l5-5m11 5l-5-5m5 5v-4m0 4h-4'
							/>
						</svg>
					</div>
				</ResizableBox>
			</div>
		</Draggable>
	)
}
