import styled, { css } from 'styled-components'

export const Row = styled.div`
	width: 475px;
	margin: 30px auto;
	box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
		inset 0 1px 0 #829fff;
	border-radius: 4px;
	background-color: #7795f8;
	position: relative;
`
export const Checkout = styled.div`
	width: 50%;
	margin: 0 auto;
	text-align: center;
	font-size: 1.5rem;
	margin-top: 2.1rem;
`

export const Button = styled.button`
	display: block;
	width: 100%;
	line-height: 1.4;
	padding-left: 5px;
	padding-right: 5px;
	white-space: normal;
	margin-top: 0;
	margin-bottom: 1.5rem;
	min-height: 3rem;
	opacity: 1
		${(props) =>
			props.fontSize
				? css`
						font-size: props.fontSize;
				  `
				: ''};
`
