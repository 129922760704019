import React, { useEffect } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Table, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { listUsers, deleteUser } from '../actions/userActions'

const UserListScreen = ({ history }) => {
	const dispatch = useDispatch()

	//Bring in userList from store
	const userList = useSelector((state) => state.userList)
	const { loading, error, users } = userList

	const userLogin = useSelector((state) => state.userLogin)
	const { userInfo } = userLogin

	const userDelete = useSelector((state) => state.userDelete)
	//Get the success from userDelete and rename it to successDelete
	const { success: successDelete } = userDelete

	useEffect(() => {
		if (userInfo && userInfo.isAdmin) {
			dispatch(listUsers())
		} else {
			history.push('/login')
		}
	}, [dispatch, history, userInfo, successDelete])

	const deleteHandler = (id) => {
		if (window.confirm('Are you sure?')) {
			dispatch(deleteUser(id))
		}
	}

	return (
		<>
			<h1 style={{ marginTop: '2rem' }}>
				<svg
					xmlns='http://www.w3.org/2000/svg'
					width='25'
					height='25'
					fill='green'
					class='bi bi-people-fill'
					viewBox='0 0 16 16'
				>
					<path d='M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z' />
					<path
						fillRule='evenodd'
						d='M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z'
					/>
					<path d='M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z' />
				</svg>
				&nbsp; Users
			</h1>

			{loading ? (
				<Loader />
			) : error ? (
				<Message variant='danger'>{error}</Message>
			) : (
				<Table striped bordered hover responsive className='table-sm'>
					<thead className='thead-dark'>
						<tr>
							<th style={{ fontSize: '1.3rem' }}>NAME</th>
							<th style={{ fontSize: '1.3rem' }}>EMAIL</th>
							<th style={{ fontSize: '1.3rem' }}>PHONE</th>
							<th style={{ fontSize: '1.3rem' }}>GOTHRAM</th>
							<th style={{ fontSize: '1.3rem' }}>ADMIN</th>
							<th style={{ fontSize: '1.3rem' }}>Edit/Delete</th>
						</tr>
					</thead>
					<tbody>
						{users.map((user) => (
							<tr key={user._id} style={{ fontSize: '1.2rem' }}>
								{/* <td>{user._id}</td> */}
								<td>{user.name}</td>
								<td>{user.email}</td>
								<td>{user.phone}</td>
								<td>{user.gotram}</td>
								{/* <td>
									<a href={`mailto:${user.email}`}>{user.email}</a>
								</td> */}

								<td>
									{user.isAdmin ? (
										/* <i className='bi bi-check' style={{ color: 'green' }}></i> */

										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='20'
											height='20'
											fill='currentColor'
											class='bi bi-check-square'
											viewBox='0 0 16 16'
										>
											<path d='M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z' />
											<path d='M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z' />
										</svg>
									) : (
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='20'
											height='20'
											fill='currentColor'
											class='bi bi-x-square'
											viewBox='0 0 16 16'
										>
											<path d='M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z' />
											<path d='M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z' />
										</svg>
									)}
								</td>
								<td>
									<LinkContainer to={`/admin/user/${user._id}/edit`}>
										<Button variant='light' className='btn-sm'>
											<svg
												xmlns='http://www.w3.org/2000/svg'
												width='16'
												height='16'
												fill='green'
												class='bi bi-pen-fill'
												viewBox='0 0 16 16'
											>
												<path d='m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001z' />
											</svg>
										</Button>
									</LinkContainer>
									<Button
										variant='light'
										className='btn-sm'
										onClick={() => deleteHandler(user._id)}
									>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='20'
											height='20'
											fill='red'
											class='bi bi-trash3'
											viewBox='0 0 16 16'
										>
											<path d='M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z' />
										</svg>
									</Button>
								</td>
							</tr>
						))}
					</tbody>
				</Table>
			)}
		</>
	)
}

export default UserListScreen
