import styled from 'styled-components'

export const AppContainer = styled.div`
	margin-top: 2rem;
	margin-bottom: -1rem;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
`

export const CardContainer = styled.div`
	width: 120rem;
	height: 40rem;
	display: flex;
	border-radius: 2rem;
	position: relative;
	border: 2px solid #fff;
	margin-top: -1.2rem;
	background: rgb(254, 193, 130);
	background: linear-gradient(
		50deg,
		rgba(254, 193, 130, 1) 20%,
		rgba(254, 160, 142, 1) 100%
	);
	backdrop-filter: blur(10px);

	@media screen and (max-width: 1000px) {
		width: 100rem;
		height: 40rem;
	}

	@media screen and (max-width: 768px) {
		width: 100%;
		height: 25rem;
	}
`

export const DetailsContainer = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
	flex: 1;
	align-items: center;
	justify-content: center;
`

export const InnerContainer = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 80%;
`

export const Header = styled.h1`
	margin: 0;
	color: #262fec;
	font-weight: 700;
	font-size: 45px;
`

export const SubHeader = styled.h3`
	color: #000;
	font-weight: 700;
	font-size: 2rem;
	margin-bottom: 2rem;

	@media screen and (max-width: 1020px) {
		font-weight: 600;
		font-size: 1.5rem;
		margin-bottom: 1.5rem;
	}

	@media screen and (max-width: 768px) {
		margin-top: -2rem;
		font-weight: 700;
		font-size: 1.4rem;
		margin-left: -3rem;
	}
	@media screen and (max-width: 480px) {
		margin-top: -2rem;
		font-weight: 700;
		font-size: 1.3rem;
		margin-left: -3rem;
	}
`

export const Text = styled.p`
	color: #000;
	font-weight: 500;
	font-size: 1.8rem;
	margin-bottom: 5rem;

	@media screen and (max-width: 1020px) {
		font-weight: 500;
		font-size: 1.8rem;
		margin-bottom: 5rem;
	}

	@media screen and (max-width: 768px) {
		font-weight: 500;
		font-size: 1.4rem;
		margin-bottom: 3rem;
		margin-left: -2rem;
	}
	@media screen and (max-width: 480px) {
		font-weight: 500;
		font-size: 1.4rem;
		margin-bottom: 3rem;
		margin-left: -2rem;
	}
`

export const FormGroup = styled.form`
	display: flex;
	position: relative;
	height: 6rem;
	margin-top: 1rem;

	@media screen and (max-width: 1000px) {
		margin-top: 1rem;
		margin-left: -1rem;
	}

	@media screen and (max-width: 768px) {
		margin-top: 1rem;
		margin-left: -1rem;
	}
`

export const EmailInput = styled.input`
	outline: none;
	border: none;
	background-color: #fff;
	padding-left: 1.5rem;
	padding-right: 16rem;
	border-radius: 1.5rem;
	font-size: 2rem;
	color: #000;
	height: 100%;

	&::placeholder {
		color: #272727;
	}

	@media screen and (max-width: 1020px) {
		margin-left: -1.5rem;
	}

	@media screen and (max-width: 768px) {
		margin-left: -1.5rem;
	}
	@media screen and (max-width: 480px) {
		margin-left: -1.5rem;
	}
`

export const SubscribeButton = styled.button`
	position: absolute;
	right: 8rem;
	top: 0;
	height: 100%;
	border: none;
	outline: none;
	color: #fff;
	${'' /* background-color: #262fec; */}
	background: linear-gradient(90deg, #E80A89 0%, #F15B2A 100%);
	font-size: 2rem;
	font-weight: 600;
	cursor: pointer;
	transition: all 300ms ease-in-out;

	border-bottom-right-radius: 1.5rem;
	border-top-right-radius: 1.5rem;
	padding: 0 1rem;

	&:hover {
		background-color: #353a40;
		${'' /* background-color: ##fe6b8b; */}
		${'' /* background-color: #1820bb; */}
	}

	@media screen and (max-width: 1024px) {
		right: -5rem;
		top: 0;
		border-bottom-right-radius: 0rem;
		border-top-right-radius: 0rem;
	}

	@media screen and (max-width: 768px) {
		right: -6rem;
		top: 0;
		border-bottom-right-radius: 0rem;
		border-top-right-radius: 0rem;
	}
	@media screen and (max-width: 480px) {
		right: -1.2rem;
		top: 0;
		border-bottom-right-radius: 0rem;
		border-top-right-radius: 0rem;
	}
`

export const SideImageContainer = styled.div`
	max-width: 35%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 1;
	position: relative;
	background: rgb(148, 147, 247);
	background: linear-gradient(
		217deg,
		rgba(148, 147, 247, 1) 0%,
		rgba(210, 194, 221, 1) 100%
	);
	clip-path: polygon(0 0, 100% 0, 100% 100%, 15% 100%);
	border-top-right-radius: 26px;
	border-bottom-right-radius: 26px;
`

export const Image = styled.div`
	width: 100%;
	${'' /* height: 22em; */}
	height: 100%;

	img {
		width: 100%;
		height: 100%;
		border-top-right-radius: 2rem;
		border-bottom-right-radius: 2rem;

		@media screen and (max-width: 1020px) {
			grid-template-columns: 1fr 1fr;
		}

		@media screen and (max-width: 768px) {
			display: none;
		}
	}
`
//Pay by check screen
export const TextInput = styled.input`
	outline: none;
	border: none;
	background-color: #fff;
	${'' /* padding-left: 1.5rem; */}
	${
		'' /* padding-right: 16rem;
	border-radius: 1.5rem; */
	}
	font-size: 1.4rem;
	${
		'' /* color: #000;
	height: 100%; */
	}

	${
		'' /* &::placeholder {
		color: #272727;
	} */
	}

	${
		'' /* @media screen and (max-width: 1020px) {
		margin-left: -1.5rem;
	}

	@media screen and (max-width: 768px) {
		margin-left: -1.5rem;
	}
	@media screen and (max-width: 480px) {
		margin-left: -1.5rem;
	} */
	}
`
