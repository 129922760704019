import React, { useState, useEffect } from 'react'
import { Form, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import FormContainer from '../components/FormContainer'
import { resetPass } from '../actions/userActions'
import {
	LoginContainer,
	H1heading,
	H2heading,
	StatusMessages,
	Img,
} from './SignInScreenElements'
import SaiBhagawan from '../assests/saibaba-newburgh1-modified.png'

const ResetPassScreen = ({ location, history }) => {
	const [password, setPassword] = useState('')
	const [confirmPassword, setConfirmPassword] = useState('')

	const [message, setMessage] = useState('')
	const [validationError, setValidationError] = useState('')

	const dispatch = useDispatch()

	const userLogin = useSelector((state) => state.userLogin)

	const { loading, error, userInfo } = userLogin

	//Get all the URL Params
	const params = new URLSearchParams(location.search)

	console.log('Token URL Params:', params)

	//Get the token
	const resetToken = params.get('token')

	const redirect = location.search ? location.search.split('=')[2] : '/'

	console.log('ResetPassScreen: redirect', redirect)

	useEffect(() => {
		if (userInfo) {
			history.push(redirect)
		}
	}, [history, userInfo, redirect])

	const submitHandler = (e) => {
		e.preventDefault()
		//DISPATCH resetpass
		if (password !== confirmPassword) {
			setValidationError('Passwords do not match')
		} else if (password.length <= 7) {
			setValidationError('Password must be at least 8 characters long')
		} else {
			dispatch(resetPass(password, resetToken))
		}
	}

	return (
		<>
			<LoginContainer>
				<div
					style={{
						textAlign: 'center',
						marginTop: '1rem',
					}}
				>
					<Img src={SaiBhagawan} alt='sing in screen' />
				</div>
				<FormContainer>
					<H1heading> Reset Password </H1heading>
					<StatusMessages style={{ fontSize: '1.5rem' }}>
						{validationError && (
							<Message variant='danger'>{validationError}</Message>
						)}
						{message && <Message variant='sucess'>{message}</Message>}
						{error && <Message variant='danger'>{error}</Message>}
					</StatusMessages>

					{loading && <Loader />}

					<Form onSubmit={submitHandler}>
						<H2heading>
							<Form.Group controlId='email'>
								<Form.Label>Password</Form.Label>

								<Form.Control
									style={{ fontSize: '1.4rem' }}
									type='password'
									placeholder='Enter your password'
									value={password}
									required
									onChange={(e) => setPassword(e.target.value)}
								></Form.Control>
							</Form.Group>

							<Form.Group
								controlId='password'
								style={{
									paddingTop: '2rem',
								}}
							>
								<Form.Label>Confirm Password</Form.Label>
								<Form.Control
									style={{ fontSize: '1.4rem' }}
									type='password'
									placeholder='Confirm your password'
									required
									value={confirmPassword}
									onChange={(e) => setConfirmPassword(e.target.value)}
								></Form.Control>
							</Form.Group>
						</H2heading>
						<Button
							type='submit'
							variant='primary'
							style={{
								fontSize: '1.4rem',
								marginLeft: '20rem',
								marginTop: '1rem',
								marginBottom: '2rem',
								border: '0.2rem solid goldenrod',
							}}
						>
							Change Password
						</Button>
					</Form>
				</FormContainer>
			</LoginContainer>
		</>
	)
}

export default ResetPassScreen
