import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, ListGroup, Image, Button, Card } from 'react-bootstrap'
import Message from '../components/Message'
import {
	MinusCircleIcon,
	PlusCircleIcon,
	TrashIcon,
} from '../components/cartIcon'
import {
	addToCart,
	addCartItem,
	decreaseCartItem,
	removeFromCart,
} from '../actions/cartActions'

const CartScreen = ({ match, location, history }) => {
	//get productid from url
	const productId = match.params.id

	//get user info
	const userLogin = useSelector((state) => state.userLogin)
	const { userInfo } = userLogin

	//get from url query params ?qty=1
	const qty = location.search ? Number(location.search.split('=')[1]) : 1

	const dispatch = useDispatch()

	const cart = useSelector((state) => state.cart)
	const { cartItems } = cart

	// console.log('CartScreen- cartItems:', cartItems)

	// cartItems.map((item) => console.log('Cart item: ', item))

	useEffect(() => {
		if (productId) {
			dispatch(addToCart(productId, qty))
		}
	}, [dispatch, productId, qty])

	const addToCartHandler = (product) => {
		dispatch(addCartItem(product))
	}

	const decreaseCartHandler = (product) => {
		dispatch(decreaseCartItem(product))
	}

	const removeFromCartHandler = (product) => {
		dispatch(removeFromCart(product))
	}

	const checkoutHandler = () => {
		// history.push('/login?redirect=shipping')

		//Check user is logged in or not. If not prompt login screen and redirect to appropriate page
		if (userInfo && userInfo.isAdmin) {
			history.push('/login?redirect=payment')
		} else {
			history.push('/login?redirect=placeorder')
		}
	}

	return (
		<>
			<Row style={{ marginTop: '2rem', marginBottom: '5rem' }}>
				<Col md={8}>
					<h1>Shopping Cart</h1>
					{cartItems.length === 0 ? (
						<h5>
							<Message>
								Your cart is empty{' '}
								<Link
									style={{
										color: '#fff',
										fontSize: '1rem',
										backgroundColor: '#e67e22',
										padding: '.5rem',
										borderRadius: '.5rem',
									}}
									to='/pooja'
								>
									Go Back 👈
								</Link>
							</Message>
						</h5>
					) : (
						<ListGroup>
							{cartItems.map((item) => (
								<ListGroup.Item key={item.product._id}>
									<Row>
										<Col md={2}>
											<Image
												src={item.product.image}
												alt={item.product.description}
												fluid
												rounded
											/>
										</Col>
										<Col md={2}>
											<p style={{ fontSize: '1.2rem', marginTop: '1rem' }}>
												<strong>{`${item.product.name}`}</strong>
											</p>
											{/* <Link to={`/product/${item.product}`}>
												{item.product.description}
											</Link> */}
										</Col>
										<Col md={2}>
											<p style={{ fontSize: '1.2rem', marginTop: '2rem' }}>
												<strong>{`Price: ${item.product.price}`}</strong>
											</p>
										</Col>
										<Col md={2}>
											<p style={{ fontSize: '1.2rem', marginTop: '2rem' }}>
												<strong>{`Quantity: ${item.cartQuantity}`}</strong>
											</p>

											{/* <Form.Control
												as='select'
												value={1}
												onChange={(e) =>
													dispatch(addToCart(item.product, Number(1)))
												}
											> */}
											{/* {[...Array(item.countInStock).keys()].map((x) => (
													<option key={x + 1} value={x + 1}>
														{x + 1}
													</option>
												))} */}
											{/* </Form.Control> */}
										</Col>

										<Col md={2}>
											{/* <Button
												type='button'
												variant='light'
												onClick={() => removeFromCartHandler(item.product)}
											> */}
											{/* <i className='fas fa-trash'></i> */}
											{/* </Button> */}
											<Button
												type='button'
												style={{
													color: '#fff',
													backgroundColor: '#1a1a1a',
													marginTop: '1rem',
												}}
												onClick={() => addToCartHandler(item.product)}
											>
												<PlusCircleIcon width='20px' />
											</Button>
										</Col>
										<Col md={2}>
											{item.cartQuantity === 1 && (
												<Button
													type='button'
													style={{
														color: '#fff',
														backgroundColor: '#d9534f',
														marginTop: '1rem',
													}}
													onClick={() => removeFromCartHandler(item.product)}
												>
													<TrashIcon width='20px' />
												</Button>
											)}

											{item.cartQuantity > 1 && (
												<Button
													type='button'
													style={{
														color: '#fff',
														backgroundColor: '#d9534f',
														marginTop: '1rem',
													}}
													onClick={() => decreaseCartHandler(item.product)}
												>
													<MinusCircleIcon width='20px' />
												</Button>
											)}
										</Col>
									</Row>
								</ListGroup.Item>
							))}
						</ListGroup>
					)}
				</Col>
				<Col md={4}>
					<Card>
						<ListGroup variant='flush'>
							<ListGroup.Item>
								<h2>
									Total Items in Cart: (
									{cartItems.reduce((acc, item) => acc + item.cartQuantity, 0)})
								</h2>

								<p style={{ fontSize: '1.2rem', marginTop: '2rem' }}>
									<strong>{`Total Price: `}</strong>$
									{cartItems
										.reduce(
											(acc, item) =>
												acc + item.cartQuantity * item.product.price,
											0
										)
										.toFixed(2)}
								</p>
							</ListGroup.Item>
							<ListGroup.Item>
								<Button
									style={{
										color: '#fff',
										fontSize: '1.2rem',
										paddingTop: '15px',
										paddingBottom: '15px',
									}}
									type='button'
									className='btn-block'
									disabled={cartItems.length === 0}
									onClick={checkoutHandler}
								>
									Proceed To Checkout
								</Button>
							</ListGroup.Item>
						</ListGroup>
					</Card>
				</Col>
			</Row>
		</>
	)
}

export default CartScreen

//In the above ListGroup the values for all the fields comes from cartAction.
//If you change the qty it will dispatch add to cart to update the cart
