import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { saveUserSelected, createUser } from '../../actions/userActions'

import { Table, Button } from 'react-bootstrap'
import Message from '../../components/Message'
import Loader from '../../components/Loader'

import './AdminHomeElements.css'

import { Icon } from '@iconify/react'
import { USER_CREATE_RESET } from '../../constants/userConstants'

const SearchBar = ({ history, placeholder, data }) => {
	const [filteredData, setFilteredData] = useState([])
	const [wordEntered, setWordEntered] = useState('')

	const userLogin = useSelector((state) => state.userLogin)
	const { userInfo } = userLogin

	const dispatch = useDispatch()

	//For admin created dummy user
	const userCreate = useSelector((state) => state.userCreate)

	const {
		loading: loadingCreate,
		error: errorCreate,
		success: successCreate,
		user: createdUser,
	} = userCreate

	useEffect(() => {
		dispatch({ type: USER_CREATE_RESET })

		if (!userInfo.isAdmin) {
			// history.push('/login')
			document.location.href = '/login'
		}

		if (successCreate && createdUser != null) {
			// history.push(`/admin/user/${createdUser._id}/add`)
			document.location.href = `/admin/user/${createdUser._id}/add`
		}
	}, [dispatch, userInfo, history, successCreate, createdUser])

	const handleFilter = (event) => {
		const searchWord = event.target.value
		setWordEntered(searchWord)
		const newFilter = data.filter((value) => {
			return (
				value.name.toLowerCase().includes(searchWord.toLowerCase()) ||
				value.email.toLowerCase().includes(searchWord.toLowerCase()) ||
				value.phone.toLowerCase().includes(searchWord.toLowerCase())
			)
		})

		if (searchWord === '') {
			setFilteredData([])
		} else {
			setFilteredData(newFilter)
		}
	}

	const clearInput = () => {
		setFilteredData([])
		setWordEntered('')
	}

	const addUserHandler = () => {
		//Create New User
		dispatch(createUser())
	}

	const selectHandler = (id, name, email, phone) => {
		const selectedUser = { userID: id, name: name, email: email, phone: phone }

		dispatch(saveUserSelected(selectedUser))
		// localStorage.setItem('selectedUser', JSON.stringify(selectedUser))

		// history.push('/pooja')
		document.location.href = '/pooja'
	}

	return (
		<>
			<div style={{ marginTop: '3rem' }}>
				<Button
					style={{
						fontSize: '1.4rem',
						paddingLeft: '1rem',
						paddingRight: '1rem',
						paddingTop: '1rem',
						paddingBottom: '1rem',
						borderRadius: '.5rem',
						color: '#fff',
						backgroundColor: '#e67e22',
						boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
						transition: '0.3s',
						textAlign: 'center',
						textDecoration: 'none',
					}}
					type='button'
					onClick={() => addUserHandler()}
				>
					Add Devotee
				</Button>
				{loadingCreate && <Loader />}
				{errorCreate && <Message variant='danger'>{errorCreate}</Message>}
				<div className='search'>
					<div className='searchInputs'>
						<input
							type='text'
							placeholder={placeholder}
							value={wordEntered}
							onChange={handleFilter}
						/>
						<div className='searchIcon'>
							{filteredData.length === 0 ? (
								<Icon
									icon='mdi:account-search'
									width={26}
									height={26}
									color='green'
								/>
							) : (
								<Icon
									icon='mdi:account-cancel'
									width={26}
									height={26}
									color='green'
									className='clearBtn'
									onClick={clearInput}
								/>
							)}
						</div>
					</div>

					<Table striped bordered hover responsive className='table-sm'>
						<thead className='thead-dark'>
							{/* <tr>
						<th style={{ fontSize: '1.3rem' }}>NAME</th>
						<th style={{ fontSize: '1.3rem' }}>EMAIL</th>
						<th style={{ fontSize: '1.3rem' }}>PHONE</th>
						<th style={{ fontSize: '1.3rem' }}>SELECT</th>
					</tr> */}
						</thead>
						<tbody>
							{filteredData.length !== 0 && (
								<div className='dataResult'>
									{filteredData.slice(0, 15).map((value) => {
										return (
											<tr key={value._id} style={{ fontSize: '1.2rem' }}>
												{/* <td>{user._id}</td> */}
												<td width='40%'>{value.name}</td>
												<td width='35%'>{value.email}</td>
												<td width='25%'>{value.phone}</td>

												<td>
													<Button
														variant='light'
														className='btn-sm'
														onClick={() =>
															selectHandler(
																value._id,
																value.name,
																value.email,
																value.phone
															)
														}
													>
														<Icon
															icon='bxs:select-multiple'
															width={26}
															height={26}
															color='green'
														/>
													</Button>
												</td>
											</tr>
										)
									})}
								</div>
							)}
						</tbody>
					</Table>
				</div>
			</div>
		</>
	)
}

export default SearchBar
