import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import validator from 'email-validator'

import { newsLetter } from '../../actions/userActions'

import {
	DetailsContainer,
	InnerContainer,
	SubHeader,
	Text,
	FormGroup,
	EmailInput,
	SubscribeButton,
} from './NewsLetterElements'

export const NewsLetterDetails = ({ history }) => {
	const [email, setEmail] = useState('')

	const [message, setMessage] = useState('')

	const dispatch = useDispatch()

	//get the response state from store.js
	const userNewsLetter = useSelector((state) => state.userNewsLetter)

	const { loading, success, error } = userNewsLetter

	useEffect(() => {}, [dispatch, history, success])

	// const inputHandler = (e) => {
	// 	const inputName = e.currentTarget.name
	// 	const value = e.currentTarget.value
	// 	setEmail((prev) => ({ ...prev, [inputName]: value }))

	// 	console.log(e.currentTarget.value)
	// }

	const submitHandler = (e) => {
		e.preventDefault()
		// console.log('email1:', email)
		// console.log(e.target.name)
		// console.log(e.currentTarget.value)

		//DISPATCH REGISTER
		if (validator.validate('email')) {
			setMessage('Please check the format and re-enter')
		} else {
			dispatch(newsLetter(email))
		}
	}

	return (
		<DetailsContainer style={{ fontSize: '1.5rem' }}>
			{message && <Message variant='success'>{message}</Message>}
			{error && <Message variant='danger'>{error}</Message>}
			{success && (
				<Message variant='success'>Signed Up! Check your email inbox</Message>
			)}
			{loading && <Loader />}

			<InnerContainer>
				{/* <Header>Hey, wait...</Header> */}
				<SubHeader> 🙏 Subscribe to Temple Events</SubHeader>
				<Text>
					Sign up below to receive weekly pooja schedule, news letters, events
					and everything you need to know.
				</Text>

				<FormGroup onSubmit={submitHandler}>
					<EmailInput
						type='email'
						placeholder='Enter your email'
						required
						value={email}
						onChange={(e) => setEmail(e.target.value)}
					/>
					<SubscribeButton type='submit'>Subscribe</SubscribeButton>
				</FormGroup>
			</InnerContainer>
		</DetailsContainer>
	)
}
