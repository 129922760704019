import React from 'react'
import { Container } from 'react-bootstrap'
import {
	FooterContainer,
	FooterWrap,
	FooterLinksContainer,
	FooterLinksWrapper,
	FooterLinkItems,
	FooterLinkTitle,
	FooterLink,
	SocialMedia,
	SocialMediaWrap,
	SocialLogo,
	WebsiteRights,
	SocialIcons,
	SocialIconLink,
} from './FooterElements'
import {
	FaYoutube,
	FaFacebook,
	FaInstagram,
	FaTwitter,
	FaLinkedin,
} from 'react-icons/fa'

import { animateScroll as scroll } from 'react-scroll'

const Footer = () => {
	const toggleHome = () => {
		scroll.scrollToTop()
	}

	return (
		<Container>
			<FooterContainer>
				<FooterWrap>
					<FooterLinksContainer>
						<FooterLinksWrapper>
							<FooterLinkItems>
								<FooterLinkTitle> About us</FooterLinkTitle>
								<FooterLink to='/'>How it works</FooterLink>
								<FooterLink to='/'>Donate</FooterLink>
								<FooterLink to='/'>Terms of service</FooterLink>
							</FooterLinkItems>
							<FooterLinkItems>
								<FooterLinkTitle> Contact us</FooterLinkTitle>
								<FooterLink to='/'>Contact</FooterLink>
								<FooterLink to='/'>Volunteer</FooterLink>
								<FooterLink to='/'>Sponsor</FooterLink>
							</FooterLinkItems>
						</FooterLinksWrapper>
						<FooterLinksWrapper>
							<FooterLinkItems>
								<FooterLinkTitle> Videos</FooterLinkTitle>
								<FooterLink to='/'>Submit Video</FooterLink>
							</FooterLinkItems>
							<FooterLinkItems>
								<FooterLinkTitle> Social Media</FooterLinkTitle>
								<FooterLink to='/'>Facebook</FooterLink>
								<FooterLink to='/'>Youtube</FooterLink>
								<FooterLink to='/'>Twitter</FooterLink>
							</FooterLinkItems>
						</FooterLinksWrapper>
					</FooterLinksContainer>
					<SocialMedia>
						<SocialMediaWrap>
							<SocialLogo to='/' onClick={toggleHome}>
								SAI DHAM
							</SocialLogo>
							<WebsiteRights>
								Saidham © {new Date().getFullYear()} All rights reserved.{' '}
							</WebsiteRights>
							<SocialIcons>
								<SocialIconLink href='/' target='_blank' aria-label='Facebook'>
									<FaFacebook />
								</SocialIconLink>
								<SocialIconLink href='/' target='_blank' aria-label='Instagram'>
									<FaInstagram />
								</SocialIconLink>
								<SocialIconLink href='/' target='_blank' aria-label='Youtube'>
									<FaYoutube />
								</SocialIconLink>
								<SocialIconLink href='/' target='_blank' aria-label='Twitter'>
									<FaTwitter />
								</SocialIconLink>
								<SocialIconLink
									href='//www.linkedin.com/in/ramikreddy/'
									target='_blank'
									aria-label='Linkedin'
								>
									<FaLinkedin />
								</SocialIconLink>
							</SocialIcons>
						</SocialMediaWrap>
					</SocialMedia>
				</FooterWrap>
			</FooterContainer>
		</Container>
	)
}

export default Footer
