import {
	START_VIDEO_PLAYER_REQUEST,
	START_VIDEO_PLAYER_SUCCESS,
	START_VIDEO_PLAYER_FAIL,
	STOP_VIDEO_PLAYER_REQUEST,
	STOP_VIDEO_PLAYER_SUCCESS,
	STOP_VIDEO_PLAYER_FAIL,
} from '../constants/youtubeConstants'

const initialState = {
	navbar: true,
	videoId: '',
	visible: false,
}

export const youtubeVideoReducer = (state = initialState, action) => {
	switch (action.type) {
		case START_VIDEO_PLAYER_REQUEST:
			return {
				initialState: action.payload,
			}
		case START_VIDEO_PLAYER_SUCCESS:
			return {
				initialState: action.payload,
			}
		case START_VIDEO_PLAYER_FAIL:
			return {
				error: action.payload,
			}
		case STOP_VIDEO_PLAYER_REQUEST:
			return {
				initialState: action.payload,
			}
		case STOP_VIDEO_PLAYER_SUCCESS:
			return {
				initialState: action.payload,
			}
		case STOP_VIDEO_PLAYER_FAIL:
			return {
				error: action.payload,
			}

		default:
			return state
	}
}
