import React from 'react'
import SaiBhagawan1 from '../assests/Saibaba8.jpg'
import SaiBhagawan2 from '../assests/Saibaba10.jpg'
// import './styles.css'

import {
	InfoContainer,
	Column1,
	SubTitle,
	ImgWrap1,
	Img,
	Img1,
	HeaderTitle,
	Grid2Layout,
	H3heading,
} from './AboutSectionElements'

export const AboutSection = () => {
	return (
		<>
			<InfoContainer>
				<HeaderTitle style={{ textAlign: 'center' }}>
					🙏 Sri Shirdi Saibaba Sansthan of Tristate 🙏
				</HeaderTitle>
				{/* Step 1 */}
				<Grid2Layout>
					<Column1>
						<H3heading>Help the Community Grow</H3heading>
						<SubTitle>
							Sri Shirdi Saibaba Sansthan of Tristate is a non-profit
							organization aimed at serving Sri Shirdi Saibaba with Shraddha (
							Faith) and Saboori (Devotion) as two pillars of excellence as
							designated by our Sadguru Sri Saibaba.
						</SubTitle>
						<SubTitle>
							Along with daily Arthi and Pooja service, community service as
							well as youth development service are other important activities
							carried out by the organization both in the temple premises as
							well as in the community.
						</SubTitle>
					</Column1>
					<ImgWrap1>
						<Img src={SaiBhagawan1} alt='infosection1' />
					</ImgWrap1>

					{/* step 2 */}

					<ImgWrap1>
						<Img1 src={SaiBhagawan2} alt='infosection1' />
					</ImgWrap1>
					<Column1>
						<H3heading>Find us at below Address</H3heading>
						<SubTitle>6299 Oak Grove Rd, Newburgh, Indiana 47630</SubTitle>
						<SubTitle>
							<strong>Telephone: </strong> (812) 490 0021
						</SubTitle>
						<SubTitle>
							<strong>Email:</strong> shirdisaisansthanoftristates@gmail.com
						</SubTitle>
						<SubTitle>
							<strong>Directions:</strong> I-64 Exit 29-A; I-164 to Lloyd
							Express way Exit-7B East, After appx 3 miles, Turn left on SR 261,
							Right on Oak Grove Road, Temple will be on your right hand side in
							a half mile.
						</SubTitle>
					</Column1>
				</Grid2Layout>
			</InfoContainer>
		</>
	)
}
