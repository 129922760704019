import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import FormContainer from '../components/FormContainer'
import { getUserDetails, updateUser } from '../actions/userActions'
import { USER_UPDATE_RESET } from '../constants/userConstants'

const UserEditScreen = ({ match, history }) => {
	const userId = match.params.id

	const [name, setName] = useState('')
	const [email, setEmail] = useState('')
	const [phone, setPhone] = useState('')
	const [gotram, setGotram] = useState('')
	const [isAdmin, setIsAdmin] = useState(false)

	const dispatch = useDispatch()

	//get the user register state
	const userDetails = useSelector((state) => state.userDetails)
	const { loading, error, user } = userDetails

	const userUpdate = useSelector((state) => state.userUpdate)
	//rename loading to loadingUpdate, errorUpdate, successUpdate
	const {
		loading: loadingUpdate,
		error: errorUpdate,
		success: successUpdate,
	} = userUpdate

	useEffect(() => {
		if (successUpdate) {
			dispatch({ type: USER_UPDATE_RESET })
			history.push('/admin/userlist')
		} else {
			if (!user.name || user._id !== userId) {
				dispatch(getUserDetails(userId))
				// console.log('user name: ', user.name)
				// setName(user.name)
				// setEmail(user.email)
				// setIsAdmin(user.isAdmin)
			} else {
				setName(user.name)
				setEmail(user.email)
				setPhone(user.phone)
				setGotram(user.gotram)
				setIsAdmin(user.isAdmin)
			}
		}
	}, [user, userId, dispatch, history, successUpdate])

	const submitHandler = (e) => {
		e.preventDefault()
		//DISPATCH REGISTER
		dispatch(updateUser({ _id: userId, name, email, phone, gotram, isAdmin }))
	}

	return (
		<>
			{/* <Link
				to='/admin/userList'
				className='btn btn-light my-3'
				style={{ fontSize: '1.5rem' }}
			>
				Go Back
			</Link> */}
			<Link
				className='btn btn-light my-5'
				style={{
					color: '#fff',
					fontSize: '1.2rem',
					backgroundColor: '#e67e22',
					padding: '.5rem',
					borderRadius: '.5rem',
				}}
				to='/admin/userList'
			>
				Go Back 👈
			</Link>
			<FormContainer>
				<h1 style={{ marginBottom: '2rem', marginTop: '-5rem' }}>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						width='25'
						height='25'
						fill='green'
						class='bi bi-person-fill'
						viewBox='0 0 16 16'
					>
						<path d='M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z' />
					</svg>
					&nbsp;Edit User
				</h1>
				{loadingUpdate && <Loader />}
				{errorUpdate && <Message variant='danger'>{errorUpdate}</Message>}
				{loading ? (
					<Loader />
				) : error ? (
					<Message variant='danger'>{error}</Message>
				) : (
					<Form onSubmit={submitHandler} style={{ fontSize: '1.5rem' }}>
						<Form.Group controlId='name'>
							<Form.Label>Name </Form.Label>
							<Form.Control
								style={{ fontSize: '1.3rem' }}
								type='text'
								placeholder='Enter Name'
								value={name}
								onChange={(e) => setName(e.target.value)}
							></Form.Control>
						</Form.Group>

						<Form.Group controlId='email'>
							<Form.Label>Email Address</Form.Label>
							<Form.Control
								style={{ fontSize: '1.3rem' }}
								type='email'
								placeholder='Enter email'
								// readOnly
								value={email}
								onChange={(e) => setEmail(e.target.value)}
							></Form.Control>
						</Form.Group>

						<Form.Group controlId='phone'>
							<Form.Label>Phone</Form.Label>
							<Form.Control
								style={{ fontSize: '1.3rem' }}
								type='phone'
								placeholder='Enter phone number'
								value={phone}
								onChange={(e) => setPhone(e.target.value)}
							></Form.Control>
						</Form.Group>

						<Form.Group controlId='gotram'>
							<Form.Label>Gothram</Form.Label>
							<Form.Control
								style={{ fontSize: '1.3rem' }}
								type='gotram'
								placeholder='Enter family gothram'
								value={gotram}
								onChange={(e) => setGotram(e.target.value)}
							></Form.Control>
						</Form.Group>

						<Form.Group controlId='isadmin'>
							<Form.Check
								type='checkbox'
								label='&nbsp;&nbsp;Is Admin'
								checked={isAdmin}
								onChange={(e) => setIsAdmin(e.target.checked)}
							></Form.Check>
						</Form.Group>

						<Button
							type='submit'
							variant='primary'
							style={{ fontSize: '1.4rem', marginTop: '2rem' }}
						>
							Update
						</Button>
					</Form>
				)}
			</FormContainer>
		</>
	)
}

export default UserEditScreen
