import React from 'react'
import { CardContainer, AppContainer } from './NewsLetterElements'
import { NewsLetterDetails } from './NewsLetterDetails'
import { NewsLetterImage } from './NewsLetterImage'

export const NewsLetter = () => {
	return (
		<>
			<AppContainer>
				<CardContainer>
					<NewsLetterDetails />
					<NewsLetterImage />
				</CardContainer>
			</AppContainer>
		</>
	)
}
