import React from 'react'
import SliderImage from './SliderImages'
import './slider.css'

const SliderContent = (props) => {
	return (
		<section>
			{' '}
			{SliderImage.map((slide, index) => (
				<div
					key={index}
					className={index === props.activeIndex ? 'slides active' : 'inactive'}
				>
					<img className='slide-image' src={slide.image} alt='' />
					{/* <h3 className='slide-title'>{slide.title}</h3> */}
					<h1 className='slide-text'>{slide.description}</h1>
				</div>
			))}
		</section>
	)
}

export default SliderContent
