import React from 'react'
import { AboutSection } from '../components/AboutSection'
import { FetchData } from '../components/googleMaps/FetchData'

const AboutScreen = () => {
	return (
		<>
			<AboutSection />

			<FetchData />
		</>
	)
}

export default AboutScreen

//keyword={keyword && keyword} - If keyword exists then use keyword
// {
// 	/* <div style={{ backgroundImage: `url(${infoBackground})` }}> */
// }
