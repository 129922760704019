import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import FormContainer from '../components/FormContainer'
import { listProductDetails, updateProduct } from '../actions/productActions'
import { PRODUCT_UPDATE_RESET } from '../constants/productConstants'

const ProductEditScreen = ({ match, history }) => {
	const productId = match.params.id

	const [name, setName] = useState('')
	const [price, setPrice] = useState(0)
	const [image, setImage] = useState('')
	const [brand, setBrand] = useState('')
	const [category, setCategory] = useState('')
	// const [countInStock, setCountInStock] = useState(0)
	const [description, setDescription] = useState('')
	const [uploading, setUploading] = useState(false)

	const dispatch = useDispatch()

	//get the product state
	const productDetails = useSelector((state) => state.productDetails)
	const { loading, error, product } = productDetails

	const productUpdate = useSelector((state) => state.productUpdate)
	//rename loading to loadingUpdate, errorUpdate, successUpdate
	const {
		loading: loadingUpdate,
		error: errorUpdate,
		success: successUpdate,
	} = productUpdate

	useEffect(() => {
		if (successUpdate) {
			dispatch({ type: PRODUCT_UPDATE_RESET })
			history.push('/admin/productlist')
		} else {
			if (!product.name || product._id !== productId) {
				dispatch(listProductDetails(productId))
			} else {
				setName(product.name)
				setPrice(product.price)
				setImage(product.Image)
				setBrand(product.brand)
				setCategory(product.category)
				// setCountInStock(product.countInStock)
				setDescription(product.description)
			}
		}
	}, [productId, dispatch, history, product, successUpdate])

	const uploadFileHandler = async (e) => {
		const file = e.target.files[0]
		const formData = new FormData()
		formData.append('image', file)
		setUploading(true)
		try {
			const config = {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			}

			const { data } = await axios.post('/api/upload', formData, config)

			setImage(data)
			setUploading(false)
		} catch (error) {
			console.error(error)
			setUploading(false)
		}
	}

	const submitHandler = (e) => {
		e.preventDefault()
		//UPDATE PRODUCT
		dispatch(
			updateProduct({
				_id: productId,
				name,
				price,
				image,
				brand,
				category,
				description,
			})
		)
	}

	return (
		<>
			{/* <Link to='/admin/productList' className='btn btn-light my-3'>
				Go Back
			</Link> */}
			<Link
				className='btn btn-light my-5'
				style={{
					color: '#fff',
					fontSize: '1.2rem',
					backgroundColor: '#e67e22',
					padding: '.5rem',
					borderRadius: '.5rem',
				}}
				to='/admin/productList'
			>
				Go Back 👈
			</Link>
			<FormContainer>
				<h2 style={{ marginTop: '-4rem', marginBottom: '2rem' }}>
					ADD A NEW SERVICE
				</h2>
				{loadingUpdate && <Loader />}
				{errorUpdate && <Message variant='danger'>{errorUpdate}</Message>}
				{loading ? (
					<Loader />
				) : error ? (
					<Message variant='danger'>{error}</Message>
				) : (
					<Form onSubmit={submitHandler} style={{ fontSize: '1.4rem' }}>
						<Form.Group controlId='name'>
							<Form.Label>Name </Form.Label>
							<Form.Control
								style={{ fontSize: '1.2rem' }}
								type='Name'
								placeholder='Enter Name'
								value={name}
								onChange={(e) => setName(e.target.value)}
							></Form.Control>
						</Form.Group>

						<Form.Group controlId='price'>
							<Form.Label>Price </Form.Label>
							<Form.Control
								style={{ fontSize: '1.2rem' }}
								type='number'
								placeholder='Enter price'
								value={price}
								onChange={(e) => setPrice(e.target.value)}
							></Form.Control>
						</Form.Group>

						<Form.Group controlId='image'>
							<Form.Label>Image </Form.Label>
							<Form.Control
								style={{ fontSize: '1.2rem' }}
								type='text'
								placeholder='Enter image url'
								value={image}
								onChange={(e) => setImage(e.target.value)}
							></Form.Control>
							<Form.File
								style={{ fontSize: '1.2rem' }}
								id='image-file'
								label='Choose File'
								custom
								onChange={uploadFileHandler}
							></Form.File>
							{uploading && <Loader />}
						</Form.Group>

						<Form.Group controlId='brand'>
							<Form.Label>Brand </Form.Label>
							<Form.Control
								style={{ fontSize: '1.2rem' }}
								type='text'
								placeholder='Enter brand'
								value={brand}
								onChange={(e) => setBrand(e.target.value)}
							></Form.Control>
						</Form.Group>

						<Form.Group controlId='category'>
							<Form.Label>Category </Form.Label>
							<Form.Control
								style={{ fontSize: '1.2rem' }}
								type='text'
								placeholder='Enter category'
								value={category}
								onChange={(e) => setCategory(e.target.value)}
							></Form.Control>
						</Form.Group>

						{/* <Form.Group controlId='countInStock'>
							<Form.Label>Count In Stock </Form.Label>
							<Form.Control
								type='number'
								placeholder='Enter count In stock'
								value={countInStock}
								onChange={(e) => setCountInStock(e.target.value)}
							></Form.Control>
						</Form.Group> */}

						<Form.Group controlId='description'>
							<Form.Label>Description </Form.Label>
							<Form.Control
								style={{ fontSize: '1.2rem', marginBottom: '2rem' }}
								type='text'
								placeholder='Enter description'
								value={description}
								onChange={(e) => setDescription(e.target.value)}
							></Form.Control>
						</Form.Group>

						<Button
							type='submit'
							variant='primary'
							style={{ fontSize: '1.2rem' }}
						>
							ADD SERVICE
						</Button>
					</Form>
				)}
			</FormContainer>
		</>
	)
}

export default ProductEditScreen
