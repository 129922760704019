import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const FooterContainer = styled.footer`
	background: #353a40;
	border-top-left-radius: 2rem;
	border-top-right-radius: 2rem;
	margin-top: 2rem;
	${
		'' /* width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background: rgb(254, 193, 130);
	background: linear-gradient(
		50deg,
		rgba(254, 193, 130, 1) 20%,
		rgba(254, 160, 142, 1) 100%
	); */
	}
`

// export const FooterWrap = styled.div`
// 	${'' /* padding: 48px 24px; */}
// 	display: flex;
// 	flex-direction: column;
// 	justify-content: center;
// 	align-items: center;
// 	max-width: 1100px;
// 	margin: 0 auto;
// `

export const FooterWrap = styled.div`
	${'' /* display: grid; */}
	${'' /* z-index: 1; */}
	height: 25rem;
	width: 100%;
	max-width: 1100px;
	margin-right: auto;
	margin-left: auto;
	padding: 0 24px;
	flex-direction: column;
	justify-content: center;
	display: flex;
`

export const FooterLinksContainer = styled.div`
	display: flex;
	justify-content: center;

	@media screen and (max-width: 820px) {
		padding-top: 32px;
	}
`

export const FooterLinksWrapper = styled.div`
	display: flex;

	@media screen and (max-width: 820px) {
		flex-direction: column;
	}
`

export const FooterLinkItems = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin: 16px;
	text-align: left;
	width: 160px;
	box-sizing: border-box;
	color: #fff;

	@media screen and (max-width: 420px) {
		margin: 0;
		padding: 10px;
		width: 100%;
	}
`

export const FooterLinkTitle = styled.h1`
	font-size: 16px;
	margin-bottom: 16px;
	color: #fff;
`

export const FooterLinkColumn = styled.h2`
	font-size: 14px;
	margin-bottom: 0.5rem;
	color: #fff;
`

export const FooterLinkBottom = styled.h1`
	font-size: 16px;
	margin-tope: 16px;
	color: #fff;
	justify-content: center;
`

export const FooterLink = styled(Link)`
	color: #fff;
	text-decoration: none;
	margin-bottom: 0.5rem;
	font-size: 14px;

	&:hover {
		color: #01bf71;
		transition: 0.3s ease-out;
	}
`

export const SocialMedia = styled.section`
	max-width: 100rem;
	width: 100%;
	margin-top: 2.5rem;
`

export const SocialMediaWrap = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	max-width: 1100px;
	${'' /* margin: 40px auto 0 auto; */}

	@media screen and (max-width: 820px) {
		flex-direction: column;
	}
`

export const SocialLogo = styled(Link)`
	color: #fff;
	justify-self: start;
	cursor: pointer;
	text-decoration: none;
	font-size: 1.5rem;
	display: flex;
	align-items: center;
	margin-bottom: 16px;
	font-weight: bold;
`

export const WebsiteRights = styled.small`
	color: #fff;
	margin-bottom: 16px;
	font-size: 1.5rem;
`

export const SocialIcons = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 240px;
`

export const SocialIconLink = styled.a`
	color: #fff;
	font-size: 24px;
`
