export const TOGGLE_NAVBAR_REQUEST = 'TOGGLE_NAVBAR_REQUEST'
export const TOGGLE_NAVBAR_SUCCESS = 'TOGGLE_NAVBAR_SUCCESS'
export const TOGGLE_NAVBAR_FAIL = 'TOGGLE_NAVBAR_FAIL'

export const START_VIDEO_PLAYER_REQUEST = 'START_VIDEO_PLAYER_REQUEST'
export const START_VIDEO_PLAYER_SUCCESS = 'START_VIDEO_PLAYER_SUCCESS'
export const START_VIDEO_PLAYER_FAIL = 'START_VIDEO_PLAYER_FAIL'

export const STOP_VIDEO_PLAYER_REQUEST = 'STOP_VIDEO_PLAYER_REQUEST'
export const STOP_VIDEO_PLAYER_SUCCESS = 'STOP_VIDEO_PLAYER_SUCCESS'
export const STOP_VIDEO_PLAYER_FAIL = 'STOP_VIDEO_PLAYER_FAIL'
