import React from 'react'
import { Container, Image } from 'react-bootstrap'
// import BannerImage from '../assests/Srisaidham.png'
import BannerImage from '../assests/sai-banner.png'
// import BannerImage from '../assests/banner1.jpg'

const Banner = () => {
	return (
		<Container>
			<>
				<Image src={BannerImage} fluid />
			</>
		</Container>
	)
}

export default Banner
//py-3 is padding on top (y is for y-axis)
