import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import store from './store'
import './bootstrap.min.css'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'

import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
// const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY)

// console.log('stripePromise: ', stripePromise)

ReactDOM.render(
	<Elements stripe={loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY)}>
		<Provider store={store}>
			<App />
		</Provider>
	</Elements>,
	document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
