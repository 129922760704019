import React from 'react'
import { SideImageContainer, Image } from './NewsLetterElements'
import NewsLetterImg from '../../assests/Saibaba11.jpg'

export const NewsLetterImage = () => {
	return (
		<>
			<SideImageContainer>
				<Image>
					<img src={NewsLetterImg} alt='News Letter' />
				</Image>
			</SideImageContainer>
		</>
	)
}
