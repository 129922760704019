import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col } from 'react-bootstrap'
import Product from '../components/Product'
import Message from '../components/Message'
import Loader from '../components/Loader'
import Paginate from '../components/Paginate'
import Meta from '../components/Meta'
// import { listProducts } from '../actions/productActions'
import { allDeities } from '../actions/productActions'

const MembershipScreen = ({ match }) => {
	// const keyword = match.params.keyword

	//Replace this code passing parameters to eleminate multiple screens
	const keyword = 'member'

	const pageNumber = match.params.pageNumber || 1

	const dispatch = useDispatch()

	const productList = useSelector((state) => state.productList)
	const { loading, error, products, page, pages } = productList

	useEffect(() => {
		dispatch(allDeities(keyword, pageNumber))
	}, [dispatch, keyword, pageNumber])

	return (
		<>
			<Meta />

			<h1 style={{ marginTop: '2rem' }}>
				Become a member today 🔯 help grow the community
			</h1>

			{loading ? (
				<Loader />
			) : error ? (
				<Message variant='danger'>{error}</Message>
			) : (
				<>
					<Row>
						{products.map((product) => (
							<Col key={product._id} sm={12} md={6} lg={4} xl={3}>
								<Product product={product} />
							</Col>
						))}
						<Col>
							<h3 style={{ marginTop: '2rem' }}>
								{' '}
								🙏 Thank you for choosing to become a member 🙏
							</h3>
							<ul style={{ listStyleType: 'none' }}>
								<li
									style={{
										fontSize: '1.5rem',
										marginTop: '2rem',
										marginBottom: '1rem',
									}}
								>
									Member has lot of benefits including...
								</li>
								<br />
								<li style={{ fontSize: '1.5rem' }}>
									Monthly Gothranamarchana (free for members){' '}
								</li>
								<br />
								<br />
								<li style={{ fontSize: '1.5rem' }}>
									10% Discount on all services
								</li>
							</ul>
						</Col>
					</Row>
					<Paginate
						pages={pages}
						page={page}
						keyword={keyword ? keyword : ''}
					/>
				</>
			)}
		</>
	)
}

export default MembershipScreen
