export const CART_ADD_ITEM = 'CART_ADD_ITEM'
export const CART_CLEAR_ITEMS = 'CART_CLEAR_ITEMS'
export const CART_REMOVE_ITEM = 'CART_REMOVE_ITEM'

export const CART_SAVE_SHIPPING_ADDRESS = 'CART_SAVE_SHIPPING_ADDRESS'
export const CART_SAVE_PAYMENT_METHOD = 'CART_SAVE_PAYMENT_METHOD'

export const START_ADD_TO_CART = 'START_ADD_TO_CART'
export const START_ADD_TO_CART_SUCCESS = 'START_ADD_TO_CART_SUCCESS'

export const DECREASE_CART_ITEM = 'DECREASE_CART_ITEM'
export const CART_CLEAR_ALL = 'CART_CLEAR_ALL'
