import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import FormContainer from '../components/FormContainer'
// import { getUserDetails, updateUser } from '../actions/userActions'
import { updateUser } from '../actions/userActions'
import { USER_UPDATE_RESET } from '../constants/userConstants'

const UserAddScreen = ({ match, history }) => {
	const userId = match.params.id

	console.log('UserAddScreen - userID: ', userId)

	const [name, setName] = useState('')
	const [email, setEmail] = useState('')
	const [phone, setPhone] = useState('')
	const isAdmin = false
	// const [gotram, setGotram] = useState('')
	// const [isAdmin, setIsAdmin] = useState(false)

	const dispatch = useDispatch()

	//get the user register state
	// const userCreate = useSelector((state) => state.userCreate)
	// const { loading, error, user } = userCreate

	// console.log('UserAddScreen - user: ', user)

	const userUpdate = useSelector((state) => state.userUpdate)
	//rename loading to loadingUpdate, errorUpdate, successUpdate
	const {
		loading: loadingUpdate,
		error: errorUpdate,
		success: successUpdate,
	} = userUpdate

	useEffect(() => {
		if (successUpdate) {
			dispatch({ type: USER_UPDATE_RESET })
			history.push('/adminhome')
			// } else {
			// 	if (user._id !== userId) {
			// 		dispatch(getUserDetails(userId))
			// 		// console.log('user name: ', user.name)
			// 		// setName(user.name)
			// 		// setEmail(user.email)
			// 		// setIsAdmin(user.isAdmin)
			// 	} else {
			// 		setName(user.name)
			// 		setEmail(user.email)
			// 		setPhone(user.phone)
			// 		setGotram(user.gotram)
			// 		setIsAdmin(user.isAdmin)
			// 	}
		}
		// }, [user, userId, dispatch, history, successUpdate])
	}, [userId, dispatch, history, successUpdate])

	const submitHandler = (e) => {
		e.preventDefault()
		//DISPATCH REGISTER AND UPDATE USER
		dispatch(updateUser({ _id: userId, name, email, phone, isAdmin }))
	}

	return (
		<>
			<Link
				className='btn btn-light my-5'
				style={{
					color: '#fff',
					fontSize: '1.2rem',
					backgroundColor: '#e67e22',
					padding: '.5rem',
					borderRadius: '.5rem',
				}}
				to='/adminhome'
			>
				Go Back 👈
			</Link>
			<FormContainer>
				<h1 style={{ marginBottom: '2rem', marginTop: '-5rem' }}>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						width='25'
						height='25'
						fill='green'
						className='bi bi-person-fill'
						viewBox='0 0 16 16'
					>
						<path d='M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z' />
					</svg>
					&nbsp;Add Devotee
				</h1>
				{loadingUpdate && <Loader />}
				{errorUpdate && <Message variant='danger'>{errorUpdate}</Message>}
				{/* {loading ? (
					<Loader />
				) : error ? (
					<Message variant='danger'>{error}</Message>
				) : ( */}
				<Form onSubmit={submitHandler} style={{ fontSize: '1.5rem' }}>
					<Form.Group controlId='name'>
						<Form.Label>Name </Form.Label>
						<Form.Control
							style={{ fontSize: '1.3rem' }}
							type='text'
							placeholder='Enter Name'
							value={name}
							onChange={(e) => setName(e.target.value)}
						></Form.Control>
					</Form.Group>

					<Form.Group controlId='email'>
						<Form.Label>Email Address</Form.Label>
						<Form.Control
							style={{ fontSize: '1.3rem' }}
							type='email'
							placeholder='Enter email'
							value={email}
							onChange={(e) => setEmail(e.target.value)}
						></Form.Control>
					</Form.Group>

					<Form.Group controlId='phone'>
						<Form.Label>Phone</Form.Label>
						<Form.Control
							style={{ fontSize: '1.3rem' }}
							type='phone'
							placeholder='Enter phone number'
							value={phone}
							onChange={(e) => setPhone(e.target.value)}
						></Form.Control>
					</Form.Group>

					<Button
						type='submit'
						variant='primary'
						style={{
							fontSize: '1.4rem',
							marginTop: '2rem',
							marginBottom: '2rem',
						}}
					>
						Add a Devotee
					</Button>
				</Form>
				{/* )} */}
			</FormContainer>
		</>
	)
}

export default UserAddScreen
