import React, { useEffect } from 'react'
// import { LinkContainer } from 'react-router-bootstrap'
// import { Table, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
// import Message from '../../components/Message'
// import Loader from '../../components/Loader'
import { listUsers } from '../../actions/userActions'
import SearchBar from './SearchBar'
// import MockData from './MOCK_DATA.json'
import './AdminHomeElements.css'
// import { Icon } from '@iconify/react'

// import MockData from './MOCK_DATA.json'

export const AdminHome = ({ history }) => {
	// 	const [searchText, setSearchText] = useState('')
	// 	const [filteredData, setFilteredData] = useState([])

	const dispatch = useDispatch()

	// 	//Bring in userList from store
	const userList = useSelector((state) => state.userList)
	const { users } = userList

	const userLogin = useSelector((state) => state.userLogin)
	const { userInfo } = userLogin

	// 	const textHandleChange = (e) => {
	// 		const searchWord = e.target.value
	// 		setSearchText(searchWord)

	// 		const newFilter = MockData.filter((value) => {
	// 			return value.title.toLowerCase().includes(searchWord.toLowerCase())
	// 		})

	// 		if (searchWord === '') {
	// 			setFilteredData([])
	// 		} else {
	// 			setFilteredData(newFilter)
	// 		}
	// 	}

	// 	const clearInput = () => {
	// 		setFilteredData([])
	// 		searchText('')
	// 	}

	useEffect(() => {
		if (userInfo && userInfo.isAdmin) {
			dispatch(listUsers())
		} else {
			history.push('/login')
		}
	}, [dispatch, history, userInfo])

	// dispatch(
	// 	partialUserSearch({
	// 		name: searchText.name,
	// 		email: searchText.email,
	// 	})
	// )

	// return (
	// <div className='container'>
	// <div className='search'>
	// 	<div className='searchInputs'>
	// 		<input
	// 			type='text'
	// 			placeholder='Search a Devotee...'
	// 			value={searchText}
	// 			className='form-control'
	// 			style={{
	// 				marginBottom: '7rem',
	// 				width: '40%',
	// 				marginLeft: '30rem',
	// 				fontSize: '1.4rem',
	// 			}}
	// 			onChange={textHandleChange}
	// 		/>
	// 		<div className='searchIcon'>
	// 			{filteredData.length === 0 ? (
	// 				<Icon
	// 					icon='mdi:account-search'
	// 					width={40}
	// 					height={40}
	// 					color='green'
	// 				/>
	// 			) : (
	// 				<Icon
	// 					icon='mdi:account-cancel'
	// 					width={26}
	// 					height={26}
	// 					className='clearBtn'
	// 					onClick={clearInput}
	// 				/>
	// 			)}
	// 		</div>
	// 	</div>
	// 	<table className='table table-bordered'>
	// 		<thead className='thead-dark'>
	// 			<tr>
	// 				<th style={{ fontSize: '1.3rem' }}>Full Name</th>
	// 				<th style={{ fontSize: '1.3rem' }}>Email</th>
	// 				<th style={{ fontSize: '1.3rem' }}>Phone</th>
	// 			</tr>
	// 		</thead>
	// 		<tbody style={{ fontSize: '1.3rem' }}>
	// 			{MockData.filter((val) => {
	// 				if (searchText === '') {
	// 					return val
	// 				} else if (
	// 					val.full_name.toLowerCase().includes(searchText.toLowerCase()) ||
	// 					val.email.toLowerCase().includes(searchText.toLowerCase()) ||
	// 					val.phone.toLowerCase().includes(searchText.toLowerCase())
	// 				) {
	// 					return val
	// 				}
	// 			}).map((m) => (
	// 				<tr key={m.id}>
	// 					<td>{m.full_name}</td>
	// 					<td>{m.email}</td>
	// 					<td>{m.phone}</td>
	// 				</tr>
	// 			))}
	// 		</tbody>
	// 	</table>
	// </div>
	// </div>
	// 	)
	// }

	// export const AdminHome = () => {
	return (
		<div className='searchbar'>
			<SearchBar
				placeholder='Search Devotee Name, Email or Phone...'
				data={users}
			/>
		</div>
	)
}
