import React, { useEffect } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Table, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import Paginate from '../components/Paginate'
import {
	listProducts,
	deleteProduct,
	createProduct,
} from '../actions/productActions'

import { PRODUCT_CREATE_RESET } from '../constants/productConstants'

const ProductListScreen = ({ history, match }) => {
	const pageNumber = match.params.pageNumber || 1
	const dispatch = useDispatch()

	//Bring in productList from store
	const productList = useSelector((state) => state.productList)
	const { loading, error, products, pages, page } = productList

	const productDelete = useSelector((state) => state.productDelete)
	const {
		loading: loadingDelete,
		error: errorDelete,
		success: successDelete,
	} = productDelete

	const productCreate = useSelector((state) => state.productCreate)
	const {
		loading: loadingCreate,
		error: errorCreate,
		success: successCreate,
		product: createdProduct,
	} = productCreate

	const userLogin = useSelector((state) => state.userLogin)
	const { userInfo } = userLogin

	// const productDelete = useSelector((state) => state.productDelete)
	//Get the success from userDelete and rename it to successDelete
	// const { success: successDelete } = productDelete

	useEffect(() => {
		dispatch({ type: PRODUCT_CREATE_RESET })

		if (!userInfo.isAdmin) {
			history.push('/login')
		}

		if (successCreate) {
			history.push(`/admin/product/${createdProduct._id}/edit`)
		} else {
			dispatch(listProducts('', pageNumber))
		}
	}, [
		dispatch,
		history,
		userInfo,
		successDelete,
		successCreate,
		createdProduct,
		pageNumber,
	])

	const deleteHandler = (id) => {
		if (window.confirm('Are you sure?')) {
			//DELETE PRODUCTS
			dispatch(deleteProduct(id))
		}
	}

	const createProductHandler = () => {
		//CREATE PRODUCT
		dispatch(createProduct())
	}

	return (
		<>
			<Row
				className='align-items-center'
				style={{ marginTop: '1.5rem', marginBottom: '1.5rem' }}
			>
				<Col>
					<h1>ALL SERVICES</h1>
				</Col>
				<Col className='text-right'>
					<Button
						className='my-3'
						onClick={createProductHandler}
						style={{ fontSize: '1.1rem' }}
					>
						<i className='fas fa-plus'></i> Add a Service
					</Button>
				</Col>
			</Row>
			{loadingDelete && <Loader />}
			{errorDelete && <Message variant='danger'>{errorDelete}</Message>}

			{loadingCreate && <Loader />}
			{errorCreate && <Message variant='danger'>{errorCreate}</Message>}

			{loading ? (
				<Loader />
			) : error ? (
				<Message variant='danger'>{error}</Message>
			) : (
				<>
					<Table striped bordered hover responsive className='table-sm'>
						<thead>
							<tr>
								{/* <th>ID</th> */}
								<th style={{ fontSize: '1.3rem' }}>NAME</th>
								<th style={{ fontSize: '1.3rem' }}>PRICE</th>
								<th style={{ fontSize: '1.3rem' }}>SERVICE</th>
								<th style={{ fontSize: '1.3rem' }}>TYPE</th>
								<th></th>
							</tr>
						</thead>
						<tbody style={{ fontSize: '1.2rem' }}>
							{products.map((product) => (
								<tr key={product._id}>
									{/* <td>{product._id}</td> */}
									<td>{product.name}</td>
									<td>${product.price}</td>
									<td>{product.category}</td>
									<td>{product.brand}</td>
									<td>
										<LinkContainer to={`/admin/product/${product._id}/edit`}>
											<Button variant='light' className='btn-sm'>
												<svg
													xmlns='http://www.w3.org/2000/svg'
													width='16'
													height='16'
													fill='green'
													class='bi bi-pen-fill'
													viewBox='0 0 16 16'
												>
													<path d='m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001z' />
												</svg>
											</Button>
										</LinkContainer>
										<Button
											style={{ background: '#fff' }}
											varaint='light'
											className='btn-sm'
											onClick={() => deleteHandler(product._id)}
										>
											<svg
												xmlns='http://www.w3.org/2000/svg'
												width='20'
												height='20'
												fill='red'
												class='bi bi-trash3'
												viewBox='0 0 16 16'
											>
												<path d='M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z' />
											</svg>
										</Button>
									</td>
								</tr>
							))}
						</tbody>
					</Table>
					<Paginate pages={pages} page={page} isAdmin={true} />
				</>
			)}
		</>
	)
}

export default ProductListScreen
