const SliderImages = [
	{
		title: 'Shrada 🙏 | Saburi 🙏',
		description: 'God is one – Sabka Maaalik ek',
		image: '/images/pooja/home-slider-1.jpg',
		// urls: 'https://mdbootstrap.com/img/Photos/Slides/img%20(130).jpg',
	},
	{
		title: 'Shrada 🙏 | Saburi 🙏',
		description: 'My devotees see everything as their Guru',
		image: '/images/pooja/home-slider-2.jpg',
		// urls: 'https://mdbootstrap.com/img/Photos/Slides/img%20(131).jpg',
	},
	{
		title: 'Shrada 🙏 | Saburi 🙏',
		description: 'Hands that serve are holier than lips that pray',
		image: '/images/pooja/home-slider-3.jpg',
		// urls: 'https://mdbootstrap.com/img/Photos/Slides/img%20(134).jpg',
	},
	{
		title: 'Sab ka Malik Eik',
		description:
			'Do not be obsessed by the importance of wealth. See the divine in the human being',
		image: '/images/pooja/home-slider-4.jpg',
		// urls: 'https://mdbootstrap.com/img/Photos/Slides/img%20(137).jpg',
	},
	{
		title: 'Shrada 🙏 | Saburi 🙏',
		description: 'Stay by me and keep quiet. I will do the rest',
		image: '/images/pooja/home-slider-5.jpg',
		// urls: 'https://mdbootstrap.com/img/Photos/Slides/img%20(137).jpg',
	},
	{
		title: 'Shrada 🙏 | Saburi 🙏',
		description:
			'Spend money in charity; be generous and munificent but not extravagant',
		image: '/images/pooja/home-slider-6.jpg',
		// urls: 'https://mdbootstrap.com/img/Photos/Slides/img%20(137).jpg',
	},
	{
		title: 'Shrada 🙏 | Saburi 🙏',
		description:
			'Get on with your worldly activities cheerfully, but do not forget God',
		image: '/images/pooja/home-slider-7.jpg',
		// urls: 'https://mdbootstrap.com/img/Photos/Slides/img%20(137).jpg',
	},
	{
		title: 'Sab Ka Malik Ek ',
		description:
			'Distinguish right from wrong and be honest, upright and virtuous',
		image: '/images/pooja/home-slider-8.jpg',
		// urls: 'https://mdbootstrap.com/img/Photos/Slides/img%20(137).jpg',
	},
	{
		title: 'Shrada 🙏 | Saburi 🙏',
		description:
			'Give food to the hungry, water to the thirsty, and clothes to the naked',
		image: '/images/pooja/home-slider-9.jpg',
		// urls: 'https://mdbootstrap.com/img/Photos/Slides/img%20(137).jpg',
	},
	{
		title: 'Shrada 🙏 | Saburi 🙏',
		description:
			'Give food to the hungry, water to the thirsty, and clothes to the naked',
		image: '/images/pooja/home-slider-10.jpg',
		// urls: 'https://mdbootstrap.com/img/Photos/Slides/img%20(137).jpg',
	},
]

export default SliderImages
