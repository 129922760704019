import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import FormContainer from '../components/FormContainer'
import { register } from '../actions/userActions'
import {
	RegisterContainer,
	H1heading,
	H2heading,
	StatusMessages,
	Img,
	SignUpButton,
	SignInLinkButton,
} from './SignInScreenElements'

import SaiBhagawan from '../assests/Saibaba5-modified.png'

const RegisterScreen = ({ location, history }) => {
	const [name, setName] = useState('')
	const [gotram, setGotram] = useState('')
	const [nakshatram, setNakshatram] = useState('')
	const [phone, setPhone] = useState('')
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [confirmPassword, setConfirmPassword] = useState('')
	const [message, setMessage] = useState('')
	const [validationError, setValidationError] = useState('')

	const dispatch = useDispatch()

	//get the user register state
	const userRegister = useSelector((state) => state.userRegister)

	const { loading, error, userInfo } = userRegister

	const redirect = location.search ? location.search.split('=')[1] : '/'

	useEffect(() => {
		if (userInfo) {
			history.push(redirect)
		}
	}, [history, userInfo, redirect])

	const submitHandler = (e) => {
		e.preventDefault()
		//DISPATCH REGISTER

		if (password !== confirmPassword) {
			setValidationError('Passwords do not match')
		} else if (password.length <= 7) {
			setValidationError('Password must be at least 8 characters')
		} else {
			setMessage('')
			dispatch(register(name, gotram, nakshatram, phone, email, password))
		}
	}

	return (
		<RegisterContainer>
			<div
				style={{
					textAlign: 'center',
					marginTop: '1rem',
				}}
			>
				<Img src={SaiBhagawan} alt='infosection1' />
			</div>
			<FormContainer>
				<H1heading>Sign Up</H1heading>

				<StatusMessages style={{ fontSize: '1.5rem' }}>
					{validationError && (
						<Message variant='danger'>{validationError}</Message>
					)}
					{message && <Message variant='success'>{message}</Message>}
					{error && <Message variant='danger'>{error}</Message>}
				</StatusMessages>

				{loading && <Loader />}
				<Form onSubmit={submitHandler}>
					<H2heading>
						<Form.Group controlId='name'>
							<Form.Label>Full Name</Form.Label>
							<Form.Control
								style={{ fontSize: '1.4rem', border: '1px solid black' }}
								type='Name'
								placeholder='Enter First and Last Name'
								value={name}
								required
								onChange={(e) => setName(e.target.value)}
							></Form.Control>
						</Form.Group>

						<Form.Group controlId='gotram' style={{ marginTop: '2rem' }}>
							<Form.Label>Family Gotram </Form.Label>
							<Form.Control
								style={{ fontSize: '1.4rem', border: '1px solid black' }}
								type='gotram'
								placeholder='Enter Family Gotram'
								value={gotram}
								onChange={(e) => setGotram(e.target.value)}
							></Form.Control>
						</Form.Group>

						<Form.Group controlId='nakshatram' style={{ marginTop: '2rem' }}>
							<Form.Label>Nakshatram </Form.Label>
							<Form.Control
								style={{ fontSize: '1.4rem', border: '1px solid black' }}
								type='nakshatram'
								placeholder='Enter your Nakshatram'
								value={nakshatram}
								onChange={(e) => setNakshatram(e.target.value)}
							></Form.Control>
						</Form.Group>

						<Form.Group controlId='phone' style={{ marginTop: '2rem' }}>
							<Form.Label>Mobile Number </Form.Label>
							<Form.Control
								style={{ fontSize: '1.4rem', border: '1px solid black' }}
								type='phone'
								placeholder='Enter Mobile Number'
								value={phone}
								required
								onChange={(e) => setPhone(e.target.value)}
							></Form.Control>
						</Form.Group>

						<Form.Group controlId='email' style={{ marginTop: '2rem' }}>
							<Form.Label>Email Address</Form.Label>
							<Form.Control
								style={{ fontSize: '1.4rem', border: '1px solid black' }}
								type='email'
								placeholder='Enter your Email id'
								value={email}
								required
								onChange={(e) => setEmail(e.target.value)}
							></Form.Control>
						</Form.Group>

						<Form.Group controlId='password' style={{ marginTop: '2rem' }}>
							<Form.Label>Password</Form.Label>
							<Form.Control
								style={{ fontSize: '1.4rem', border: '1px solid black' }}
								type='password'
								placeholder='Enter your Password'
								value={password}
								required
								onChange={(e) => setPassword(e.target.value)}
							></Form.Control>
						</Form.Group>

						<Form.Group
							controlId='confirmPassword'
							style={{ marginTop: '2rem' }}
						>
							<Form.Label>Confirm Password</Form.Label>
							<Form.Control
								style={{ fontSize: '1.4rem', border: '1px solid black' }}
								type='password'
								placeholder='Confirm your Password'
								value={confirmPassword}
								required
								onChange={(e) => setConfirmPassword(e.target.value)}
							></Form.Control>
						</Form.Group>
						<Row style={{ marginBottom: '2rem' }}>
							<Col>
								<SignUpButton>Register</SignUpButton>
							</Col>
							<Col
								style={{
									marginTop: '2.5rem',
									textTransform: 'none',
									marginLeft: '17%',
									color: '#010606',
									fontWeight: 'bold',
								}}
							>
								Already Registered?
							</Col>

							<Col>
								<SignInLinkButton>
									<Link
										style={{
											color: 'green',
											fontWeight: 'bold',
											textDecoration: 'none',
										}}
										to={redirect ? `/login?redirect=${redirect}` : '/login'}
									>
										Sign In
									</Link>
								</SignInLinkButton>
							</Col>

							{/* <Col style={{ marginTop: '2rem' }}>
								<Link
									style={{
										color: '#fff',
										fontSize: '1.5rem',
										backgroundColor: '#e67e22',
										padding: '0.8rem',
										borderRadius: '0.5rem',
										textDecoration: 'none',
									}}
									// to={redirect ? `/?redirect=${redirect}` : '/resetpass'}
									to={redirect ? `/login?redirect=${redirect}` : '/login'}
								>
									Sign in
								</Link>
							</Col> */}
						</Row>
					</H2heading>
				</Form>

				{/* <Row
					style={{
						marginTop: '2.5rem',
						marginBottom: '5rem',
						marginLeft: '14rem',
					}}
				>
					<Col style={{ fontSize: '1.5rem' }}>
						Already Registered? &nbsp;&nbsp;&nbsp;
						<Link
							style={{
								color: '#fff',
								fontSize: '1.5rem',
								backgroundColor: '#e67e22',
								padding: '0.8rem',
								borderRadius: '0.5rem',
								textDecoration: 'none',
							}}
							// to={redirect ? `/?redirect=${redirect}` : '/resetpass'}
							to={redirect ? `/login?redirect=${redirect}` : '/login'}
						>
							Sign in
						</Link>
					</Col>
				</Row> */}

				{/* <Row
					style={{
						marginTop: '5rem',
						marginBottom: '7rem',
						marginLeft: '14rem',
					}}
				>
					<Col style={{ fontSize: '1.5rem' }}>
						Already Registered? &nbsp;&nbsp;&nbsp;
						<Link
							style={{
								color: '#fff',
								fontSize: '1.5rem',
								backgroundColor: '#e67e22',
								padding: '0.7rem',
								borderRadius: '0.5rem',
								textDecoration: 'none',
							}}
							to={redirect ? `/login?redirect=${redirect}` : '/login'}
						>
							Login
						</Link>
					</Col>
				</Row> */}
			</FormContainer>
		</RegisterContainer>
	)
}

export default RegisterScreen
