import React, { useState, useEffect } from 'react'
import { Button, Row, Col, ListGroup, Image, Card, Form } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import { createOrder } from '../actions/orderActions'
import { ORDER_CREATE_RESET } from '../constants/orderConstants'

import './PlaceOrderScreenElements.css'
// import { TextInput } from '../components/newsLetter/NewsLetterElements'

export const PlaceOrderByCheckScreen = ({ history: { push } }) => {
	const [processing, setProcessing] = useState(false)

	const [bankName, setbankName] = useState('')
	const [checkNumber, setCheckNumber] = useState('')
	const [amountPaid, setAmountPaid] = useState('')

	const dispatch = useDispatch()

	const cart = useSelector((state) => state.cart)

	const userSelect = useSelector((state) => state.userSelect)

	const { userSelected } = userSelect

	const { userID } = userSelected

	// get details about the order creation is successfull or not
	const orderCreate = useSelector((state) => state.orderCreate)
	const { success } = orderCreate

	// console.log('check what is in there orderCreate: ', orderCreate)

	if (!cart.paymentMethod) {
		// history.push('/payment')
		cart.paymentMethod = 'Check'
	}

	//   Calculate prices
	const addDecimals = (num) => {
		return (Math.round(num * 100) / 100).toFixed(2)
	}

	//Calculate Cart Price
	const cartTotalPrice = addDecimals(
		cart.cartItems.reduce(
			(acc, item) => acc + item.product.price * item.cartQuantity,
			0
		)
	)

	useEffect(() => {
		if (success) {
			dispatch({ type: ORDER_CREATE_RESET })
			// setProcessing(false)
			push('/success')
		}
	}, [push, success, dispatch, cart])

	const handleSubmit = (event) => {
		event.preventDefault()

		if (userID == null || userID.length <= 0) {
			alert('Please select donor record before submitting check details !!!')
		}

		setProcessing(true)

		dispatch(
			createOrder({
				orderItems: cart.cartItems,
				userID: userID,
				bankName: bankName,
				checkNumber: checkNumber,
				// shippingAddress: cart.shippingAddress,
				paymentMethod: cart.paymentMethod,
				// itemsPrice: cart.itemsPrice,
				// shippingPrice: cart.shippingPrice,
				// taxPrice: cart.taxPrice,
				// totalPrice: cart.totalPrice,
			})
		)
	}

	return (
		<React.Fragment>
			<>
				{/* <CheckoutSteps step1 step2 step3 step4 /> */}
				<Row style={{ marginTop: '2rem' }}>
					<Col md={7}>
						<ListGroup variant='flush'>
							<ListGroup.Item>
								<h2>Payment Method:</h2>

								<strong style={{ fontSize: '1.4rem' }}>
									{cart.paymentMethod}
								</strong>
							</ListGroup.Item>

							<ListGroup.Item>
								<h2>Order Items:</h2>
								{cart.cartItems.length === 0 ? (
									<Message>Your cart is empty</Message>
								) : (
									<ListGroup variant='flush'>
										{cart.cartItems.map((item, index) => (
											<ListGroup.Item key={index}>
												<Row>
													<Col md={2}>
														<Image
															style={{ width: '100%', height: 'auto' }}
															src={item.product.image}
															alt={item.product.name}
															fluid
															rounded
														/>
													</Col>
													<Col style={{ fontSize: '1.3rem' }}>
														{/* <Link to={`/product/${item.product}`}> */}
														{item.product.name}
														{/* </Link> */}
													</Col>
													<Col md={4} style={{ fontSize: '1.3rem' }}>
														{item.cartQuantity} x ${item.product.price} = $
														{item.cartQuantity * item.product.price}
													</Col>
												</Row>
											</ListGroup.Item>
										))}
									</ListGroup>
								)}
							</ListGroup.Item>
						</ListGroup>
					</Col>
					<Col md={5}>
						{/* ----------- */}
						<Form onSubmit={handleSubmit}>
							<Card>
								<ListGroup variant='flush'>
									<ListGroup.Item>
										<h2>Enter Bank Check Details</h2>
									</ListGroup.Item>

									<ListGroup.Item>
										<Row>
											{/* <Col style={{ fontSize: '1.25rem' }}>
												Name of the Bank:
											</Col>
											<Col>
												<TextInput
													style={{ border: 'none' }}
													type='text'
													placeholder='Enter bank name'
													required
													value={bankName}
													onChange={(e) => setbankName(e.target.value)}
												/>
											</Col> */}

											<Form.Group controlId='name' style={{ width: '90%' }}>
												<Form.Label
													style={{ fontSize: '1.4rem', marginLeft: '1rem' }}
												>
													Bank Name:
												</Form.Label>
												<Form.Control
													style={{
														fontSize: '1.3rem',
														marginLeft: '1rem',
													}}
													required
													type='text'
													placeholder='Enter bank name'
													// defaultValue="Mark"
													value={bankName}
													onChange={(e) => setbankName(e.target.value)}
												/>
												{/* <Form.Control.Feedback
													type='invalid'
													style={{ fontSize: '1.3rem' }}
												>
													Please provide a bank name
												</Form.Control.Feedback> */}
											</Form.Group>
										</Row>
									</ListGroup.Item>

									<ListGroup.Item>
										<Row>
											{/* <Col style={{ fontSize: '1.25rem' }}>Check Number:</Col>
											<Col>
												<TextInput
													type='text'
													placeholder='Enter check number'
													required
													value={checkNumber}
													onChange={(e) => setCheckNumber(e.target.value)}
												/>
											</Col> */}

											<Form.Group controlId='number' style={{ width: '90%' }}>
												<Form.Label
													style={{ fontSize: '1.4rem', marginLeft: '1rem' }}
												>
													Check Number:
												</Form.Label>
												<Form.Control
													style={{ fontSize: '1.3rem', marginLeft: '1rem' }}
													required
													type='text'
													placeholder='Enter check number'
													// defaultValue="Mark"
													value={checkNumber}
													onChange={(e) => setCheckNumber(e.target.value)}
												/>
												{/* <Form.Control.Feedback
													type='invalid'
													style={{ fontSize: '1.3rem' }}
												>
													Please provide a check number
												</Form.Control.Feedback> */}
											</Form.Group>
										</Row>
									</ListGroup.Item>
									<ListGroup.Item>
										<Row>
											{/* <Col style={{ fontSize: '1.25rem' }}>Amount:</Col>
											<Col>
												<TextInput
													type='text'
													placeholder='Enter amount paid'
													required
													value={amountPaid}
													onChange={(e) => setAmountPaid(e.target.value)}
												/>
											</Col> */}
											<Form.Group controlId='amount' style={{ width: '90%' }}>
												<Form.Label
													style={{ fontSize: '1.4rem', marginLeft: '1rem' }}
												>
													Total Amount:
												</Form.Label>
												<Form.Control
													style={{
														fontSize: '1.3rem',
														marginLeft: '1rem',
													}}
													required
													type='text'
													readOnly
													placeholder={cartTotalPrice}
													// defaultValue="Mark"
													value={amountPaid}
													onChange={(e) => setAmountPaid(e.target.value)}
												/>
												{/* <Form.Control.Feedback
													type='invalid'
													style={{ fontSize: '1.3rem' }}
												>
													Please enter amount paid
												</Form.Control.Feedback> */}
											</Form.Group>
										</Row>
									</ListGroup.Item>

									<ListGroup.Item>
										<Button
											style={{
												color: '#fff',
												fontSize: '1.2rem',
												paddingTop: '15px',
												paddingBottom: '15px',
												opacity: 1,
											}}
											type='submit'
											className='btn-block'
											// onClick={() => checkoutHandler()}
											disabled={cart.cartItems.length <= 0 || processing}
										>
											{processing
												? 'PROCESSING ...'
												: ` PAY  $${cartTotalPrice} `}
										</Button>
									</ListGroup.Item>
									{/* <ListGroup.Item>
										{error && <p className='error-message'>{error}</p>}
									</ListGroup.Item> */}
								</ListGroup>
							</Card>
						</Form>
					</Col>
				</Row>
			</>
		</React.Fragment>
	)
}
