import React from 'react'
import { useDispatch } from 'react-redux'
import { startVideoPlayer } from '../actions/youtubeActions'
import Icon1 from '../assests/Saibaba1.jpg'
import Icon2 from '../assests/saibaba-newburgh4.jpg'
import Icon3 from '../assests/Saibaba3.jpg'
import Icon4 from '../assests/Saibaba4.jpg'
import Icon5 from '../assests/Saibaba5.jpg'
import Icon6 from '../assests/saibaba-newburgh6.jpg'

import {
	ServicesContainer,
	ServicesH1,
	ServicesWrapper,
	ServicesCard,
	ServicesIcon,
	ServicesH2,
} from './ServicesVideoElements'

export const video = {
	title1: 'Kakad Aarati',
	id1: 'fiC28Jcn8vI',

	title2: 'Madhyan Aarati',
	id2: 'gHm2ZBX9pSU',

	title3: 'Doop Aarati',
	id3: '6b6XN7ZvicM',

	title4: 'Shej Aarati',
	id4: 'ecl9WKRpFnY',

	title5: 'Sai Loves Everybody',
	id5: 'ki-4lTmHyfg',

	title6: 'Guru Paduka Stotram',
	id6: 'pxuhFeKm5l0',
}

const Services = () => {
	const dispatch = useDispatch()

	return (
		<ServicesContainer id='services'>
			<ServicesH1>Baba Aarati Sloka Videos </ServicesH1>
			<ServicesWrapper>
				<ServicesCard>
					<ServicesIcon
						src={Icon1}
						onClick={() => dispatch(startVideoPlayer(video.id1))}
					/>
					<ServicesH2>Kakad Aarati</ServicesH2>
				</ServicesCard>

				<ServicesCard>
					<ServicesIcon
						src={Icon2}
						onClick={() => dispatch(startVideoPlayer(video.id2))}
					/>
					<ServicesH2>Madhyan Aarati</ServicesH2>
				</ServicesCard>
				<ServicesCard>
					<ServicesIcon
						src={Icon3}
						onClick={() => dispatch(startVideoPlayer(video.id3))}
					/>
					<ServicesH2>Dhoop Aarati</ServicesH2>
				</ServicesCard>

				<ServicesCard>
					<ServicesIcon
						src={Icon4}
						onClick={() => dispatch(startVideoPlayer(video.id4))}
					/>
					<ServicesH2>Shej Aarati</ServicesH2>
				</ServicesCard>
				<ServicesCard>
					<ServicesIcon
						src={Icon5}
						onClick={() => dispatch(startVideoPlayer(video.id5))}
					/>
					<ServicesH2>Sai Bhajan</ServicesH2>
				</ServicesCard>
				<ServicesCard>
					<ServicesIcon
						src={Icon6}
						onClick={() => dispatch(startVideoPlayer(video.id6))}
					/>
					<ServicesH2>Guru Paduka</ServicesH2>
				</ServicesCard>
			</ServicesWrapper>
		</ServicesContainer>
	)
}

export default Services
