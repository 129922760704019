import styled from 'styled-components'

export const ServicesContainer = styled.div`
	border-radius: 2rem;
	position: relative;
	border: 2px solid #fff;
	margin-bottom: 1.5rem;
	padding-bottom: 3rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background: linear-gradient(limegreen, transparent),
		linear-gradient(90deg, skyblue, transparent),
		linear-gradient(-90deg, coral, transparent);

	background-blend-mode: screen;

	@media screen and (max-width: 768px) {
		height: 50%;
	}

	@media screen and (max-width: 480px) {
		height: 50%;
		display: flex;
		flex-wrap: nowrap;
		overflow-x: auto;
		width: auto;
	}
`
export const ServicesH1 = styled.h1`
	font-size: 2.5rem;
	color: #fff;
	margin-top: 1rem;
	margin-bottom: 2rem;

	@media screen and (max-width: 1024px) {
		font-size: 1.6rem;
		margin-top: 3rem;
		margin-bottom: 1rem;
	}

	@media screen and (max-width: 768px) {
		font-size: 1.7rem;
		margin-top: 4rem;
		margin-bottom: 0rem;
	}
	@media screen and (max-width: 480px) {
		font-size: 1.6rem;
		margin-top: 4rem;
		margin-bottom: 0rem;
	}
`

export const ServicesWrapper = styled.div`
	${'' /* max-width: 1200px; */}
	margin: 0 auto;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	align-items: center;
	grid-column-gap: 6rem;
	grid-row-gap: 4rem;

	@media screen and (max-width: 1020px) {
		grid-template-columns: 1fr 1fr;
		grid-column-gap: 1rem;
		grid-row-gap: 1rem;
		height: 100%;
	}

	@media screen and (max-width: 768px) {
		grid-template-columns: 1fr;
		${'' /* padding: 0 20px; */}
		height: 100%;
		grid-column-gap: 1rem;
		grid-row-gap: 1rem;
	}
`

export const ServicesCard = styled.div`
	background: #fff;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	border-radius: 1rem;
	max-height: 20rem;
	padding: 1.5rem;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
	transition: all 0.2s ease-in-out;

	&:hover {
		transform: scale(1.02);
		transition: all 0.2s ease-in-out;
		cursor: pointer;
	}
`

export const ServicesIcon = styled.img`
	height: 15rem;
	width: 12rem;
	margin-bottom: 1rem;
`

export const ServicesH2 = styled.h2`
	font-size: 1rem;
	margin-bottom: 10px;
`

export const ServicesP = styled.p`
	font-size: 1rem;
	text-align: center;
`
