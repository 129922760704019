//stripe
import {
	STRIPE_INTENT_REQUEST,
	STRIPE_INTENT_SUCCESS,
	STRIPE_INTENT_FAIL,
} from '../constants/stripeConstants'
import axios from 'axios'
import { logout } from './userActions'

export const paymentIntent = (body) => async (dispatch, getState) => {
	try {
		dispatch({
			type: STRIPE_INTENT_REQUEST,
		})

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.post(`/api/users/paymentintent`, body, config)

		// console.log('Payment Intent secret key stripeAction.js: ', data)

		dispatch({
			type: STRIPE_INTENT_SUCCESS,
			payload: data,
		})
	} catch (error) {
		const message =
			error.response && error.response.data.message
				? error.response.data.message
				: error.message

		console.log('Stripe Intent Error Message: ', message)

		if (message === 'Not authorized, token failed') {
			dispatch(logout())
		}
		dispatch({
			type: STRIPE_INTENT_FAIL,
			payload: message,
		})
	}
}
