import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import {
	productListReducer,
	productDetailsReducer,
	productDeleteReducer,
	productCreateReducer,
	productUpdateReducer,
	productReviewCreateReducer,
	productTopRatedReducer,
} from './reducers/productReducers'
import { cartReducer } from './reducers/cartReducers'
import {
	userLoginReducer,
	userRegisterReducer,
	userSelectedReducer,
	userDetailsReducer,
	userUpdateProfileReducer,
	userListReducer,
	userDeleteReducer,
	userUpdateReducer,
	userNewsLetterReducer,
	userCreateReducer,
} from './reducers/userReducers'
import {
	orderCreateReducer,
	orderDetailsReducer,
	orderPayReducer,
	orderDeliverReducer,
	orderListMyReducer,
	orderListReducer,
} from './reducers/orderReducers'

import { youtubeVideoReducer } from './reducers/youtubeReducers'
import { stripeIntentReducer } from './reducers/stripeReducers'

const reducer = combineReducers({
	productList: productListReducer,
	productDetails: productDetailsReducer,
	productDelete: productDeleteReducer,
	productCreate: productCreateReducer,
	productUpdate: productUpdateReducer,
	productReviewCreate: productReviewCreateReducer,
	productTopRated: productTopRatedReducer,
	cart: cartReducer,
	userLogin: userLoginReducer,
	userRegister: userRegisterReducer,
	userSelect: userSelectedReducer,
	userNewsLetter: userNewsLetterReducer,
	userDetails: userDetailsReducer,
	userUpdateProfile: userUpdateProfileReducer,
	userList: userListReducer,
	userDelete: userDeleteReducer,
	userUpdate: userUpdateReducer,
	userCreate: userCreateReducer,
	orderCreate: orderCreateReducer,
	orderDetails: orderDetailsReducer,
	orderPay: orderPayReducer,
	orderDeliver: orderDeliverReducer,
	orderListMy: orderListMyReducer,
	orderList: orderListReducer,
	youtubeVideo: youtubeVideoReducer,
	stripeIntent: stripeIntentReducer,
})

//Get the state from local storage if needed
const cartItemsFromStorage = localStorage.getItem('cartItems')
	? JSON.parse(localStorage.getItem('cartItems'))
	: []

// Get the user selected from local storage for the admin cart check out
const userSelectedFromStorage = localStorage.getItem('userSelected')
	? JSON.parse(localStorage.getItem('userSelected'))
	: []

//Get the user state from local storage if needed
const userInfoFromStorage = localStorage.getItem('userInfo')
	? JSON.parse(localStorage.getItem('userInfo'))
	: null

// const shippingAddressFromStorage = localStorage.getItem('shippingAddress')
// 	? JSON.parse(localStorage.getItem('shippingAddress'))
// 	: {}

// const initialState = {
// 	cartItems: localStorage.getItem('cartItems')
// 		? JSON.parse(localStorage.getItem('cartItems'))
// 		: [],
// 	cartTotalQuantity: 0,
// 	cartTotalAmount: 0,
// 	userLogin: { userInfo: userInfoFromStorage },
// }

const initialState = {
	cart: {
		cartItems: cartItemsFromStorage,
		// cartItems: [],
		// cartQuantity: 0,
		// 		// shippingAddress: shippingAddressFromStorage,
	},
	userLogin: { userInfo: userInfoFromStorage },
	userSelect: { userSelected: userSelectedFromStorage },
}

const middleware = [thunk]

//create store for state management using middleware thunk
const store = createStore(
	reducer,
	initialState,
	composeWithDevTools(applyMiddleware(...middleware))
)

export default store
