import Confetti from 'react-confetti'
import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Checkout, Button } from './SuccessScreenElements'
import { clearCart } from '../actions/cartActions'
// import { createOrder } from '../actions/orderActions'

// import useWindowSize from 'react-use/lib/useWindowSize'

export const SuccessScreen = ({ history }) => {
	const [width, setWidth] = useState('')
	const [height, setHeight] = useState(0)
	// const { width, height } = useWindowSize()

	// const cart = useSelector((state) => state.cart)

	const dispatch = useDispatch()

	useEffect(() => {
		setTimeout(() => {
			setWidth(window.innerWidth)
			setHeight(window.innerHeight)
		}, 100)

		dispatch(clearCart())
	})

	return (
		<Checkout>
			<Confetti width={width} height={height} numberOfPieces={500} />
			<h1>!!!PAYMENT RECEIVED!!!</h1>
			<h1> 🕉 Thank you for your Donation 🕉 </h1>
			<h2>
				We are currently processing your Donation and will send you a
				confirmation email shortly
			</h2>
			<div>
				<Button
					style={{
						marginTop: '2rem',
						marginBottom: '5rem',
						fontSize: '2rem',
						backgroundColor: '#1a1a1a',
						borderColor: '#1a1a1a',
						color: '#fff',
					}}
					onClick={() => history.push('/pooja')}
				>
					Continue To Services
				</Button>
			</div>
		</Checkout>
	)
}
