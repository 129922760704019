import {
	STRIPE_INTENT_REQUEST,
	STRIPE_INTENT_SUCCESS,
	STRIPE_INTENT_FAIL,
} from '../constants/stripeConstants'

export const stripeIntentReducer = (state = {}, action) => {
	switch (action.type) {
		case STRIPE_INTENT_REQUEST:
			return {
				loading: true,
			}
		case STRIPE_INTENT_SUCCESS:
			return {
				loading: false,
				success: true,
				stripeKey: action.payload,
			}
		case STRIPE_INTENT_FAIL:
			return {
				loading: false,
				error: action.payload,
			}
		default:
			return state
	}
}
