import axios from 'axios'
import {
	CART_ADD_ITEM,
	CART_REMOVE_ITEM,
	CART_SAVE_SHIPPING_ADDRESS,
	CART_SAVE_PAYMENT_METHOD,
	START_ADD_TO_CART,
	DECREASE_CART_ITEM,
	CART_CLEAR_ALL,
} from '../constants/cartConstants'

//getState will get the state of all the reducers from the store which we can use.
export const addToCart = (id, qty) => async (dispatch, getState) => {
	const { data } = await axios.get(`/api/products/${id}`)

	dispatch({
		type: CART_ADD_ITEM,

		payload: {
			product: data._id,
			name: data.name,
			image: data.image,
			price: data.price,
			qty,
		},
	})
	//save it in our local storage using localStorage API
	//Convert JSON object to string before saving
	localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems))
}

//This get executed when ADD TO CART Button is clicked on the Pooja Screen.
export const addCartItem = (product) => (dispatch, getState) => {
	dispatch({
		type: START_ADD_TO_CART,
		payload: {
			product: product,
		},
	})
	localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems))
}

export const decreaseCartItem = (product) => (dispatch, getState) => {
	dispatch({
		type: DECREASE_CART_ITEM,
		payload: {
			product: product,
		},
	})
	localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems))
}

export const removeFromCart = (product) => (dispatch, getState) => {
	dispatch({
		type: CART_REMOVE_ITEM,
		payload: {
			product: product,
		},
	})

	localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems))
}

export const clearCart = () => (dispatch) => {
	dispatch({
		type: CART_CLEAR_ALL,
	})

	localStorage.removeItem('cart')
	//Admin functionality for the selected user
	localStorage.removeItem('userSelected')
}

export const saveShippingAddress = (data) => (dispatch) => {
	dispatch({
		type: CART_SAVE_SHIPPING_ADDRESS,
		payload: data,
	})

	localStorage.setItem('cartItems', JSON.stringify(data))
}

export const savePaymentMethod = (data) => (dispatch) => {
	dispatch({
		type: CART_SAVE_PAYMENT_METHOD,
		payload: data,
	})

	localStorage.setItem('paymentMethod', JSON.stringify(data))
}
